import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";
import news2 from "../Images/news2.jpg";
import news3 from "../Images/news3.jpg";
import news4 from "../Images/news4.JPG";
import news5 from "../Images/news5.jpg";
import news6 from "../Images/news6.jpg";
import Fade from "react-reveal/Fade";

export default function NewsAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>
<header class="page-header" data-stellar-background-ratio="1.15">
	<div class="container">
		<h1  dir="rtl" lang="ar"> اخبار A.S. Properties </h1>
     <Fade bottom duration={1000} delay={500}> 		<p  dir="rtl" lang="ar">حلول متقدمة لمنزلك الجديد</p></Fade>
		  <ol class="breadcrumbAr"  dir="rtl" lang="ar" style={{    position: 'absolute',
    right: '15px',
    bottom: '-30px',
    background: '#9f8054',
    width: '85%',
    padding: '40px 15px',
    left: 'auto',
    margin: '0'
    }}>
    <li class="breadcrumbAr-item"><Link to='/' style={{textDecoration:'none'}}>الرئيسية</Link></li>
    <li class="breadcrumbAr-item active" aria-current="page">الأخبار </li>
  </ol>
	</div>

</header>




<section class="recent-posts" style={{padding:'60px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp" dir="rtl" lang="ar">
              {" "}
              <b>01</b>
             <Fade bottom duration={1000} delay={500}> <h4>
              اخبار      <span>A.S.</span> Properties 
              </h4></Fade>
              <small>اقرأ اخبارنا </small>{" "}
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s" dir="rtl" lang="ar">
           <Fade right duration={1000} delay={600}>     <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news3' >  <img src={news3} alt="Image" />{" "}</Link>
                </figure>
                <span>15, يناير 2021</span>
                <h6>
                <Link to='/news3'  style={{ textDecoration: "none" }}>
                ثلاثة أنواع من الاستثمار العقاري المربح
بالتفاصيل                 </Link>
                </h6>
                <Link to='/news3' ><button className="readMore">اقرأ المزيد  &nbsp;<i class="fas fa-caret-left"></i></button></Link>

              </div></Fade>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.10s" dir="rtl" lang="ar">
               <Fade right duration={1000} delay={600}> <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news2' >  <img src={news2} alt="Image" />{" "}</Link>
                </figure>
                <span>17, نوفمبر 2022</span>
                <h6>
                   <Link to='/news2' style={{ textDecoration: "none" }}>
                   مستقبل السوق العقاري في مصر لعام 2023
                  </Link>
                </h6>
                <Link to='/news2' >     <button className="readMore">اقرأ المزيد  &nbsp;<i class="fas fa-caret-left"></i></button></Link>

              </div></Fade>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0s" dir="rtl" lang="ar">
             <Fade right duration={1000} delay={600}>   <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news1' >
                  <img src={news1} alt="Image" />{" "}</Link>
                </figure>
                <span>2, اغسطس 2022</span>
                <h6>
                <Link to='/news1' style={{ textDecoration: "none" }}>
                نصائح للمالِك لاختيار الوسيط العقاري الأنسب 
                  </Link>
                </h6>
                <Link to='/news1'>
                <button className="readMore">اقرأ المزيد  &nbsp;<i class="fas fa-caret-left"></i></button></Link>
              </div></Fade>
            </div>

           

          


          
          </div>
          <div class="row" style={{paddingTop:'4%'}}>
            
          <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s" dir="rtl" lang="ar">
               <Fade right duration={1000} delay={600}> <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news6' >   <img src={news6} alt="Image" />{" "}</Link>
                </figure>
                <span>15, يناير 2021</span>
                <h6>
                <Link to='/news6'  style={{ textDecoration: "none" }}>
                عوامل نجاح السوق العقاري
                بما يتضمنه   </Link>
                </h6>
                <Link to='/news6' ><button className="readMore">اقرأ المزيد  &nbsp;<i class="fas fa-caret-left"></i></button></Link> 

              </div></Fade>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s" dir="rtl" lang="ar">
               <Fade right duration={1000} delay={600}> <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news5' >    <img src={news5} alt="Image" />{" "}</Link>
                </figure>
                <span>15, يناير 2021</span>
                <h6>
                <Link to='/news5'  style={{ textDecoration: "none" }}>
                مصطلحات التثمين العقاري
                بالتفاصيل ومفهومها     </Link>
                </h6>
                <Link to='/news5' ><button className="readMore">اقرأ المزيد  &nbsp;<i class="fas fa-caret-left"></i></button></Link>

              </div></Fade>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s" dir="rtl" lang="ar">
              <Fade right duration={1000} delay={600}>  <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news4' >     <img src={news4} alt="Image" />{" "}</Link>
                </figure>
                <span>15, يناير 2021</span>
                <h6>
                <Link to='/news4' style={{ textDecoration: "none" }}>
                انواع الشقق السكنية التي يجب التعرف عليها
              </Link>
                </h6>
                <Link to='/news4' >  <button className="readMore">اقرأ المزيد  &nbsp;<i class="fas fa-caret-left"></i></button></Link>

              </div></Fade>
            </div>
            
          
          </div>
        </div>
      </section>
</>  )
}
