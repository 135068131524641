import logo from './logo.svg';
import '../src/Styles/Style.scss';
import { useEffect, useState } from 'react';
import { Routes ,Route} from 'react-router-dom';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import NavbarAr from './Components/Navbar/NavbarAr';
import FooterAr from './Components/Footer/FooterAr';
import Home from './Components/Home/Home';
import HomeAr from './Components/Home/HomeAr';

// import '../src/css/fontawesome.min.css';
import '../src/css/animate.min.css';
import '../src/css/fancybox.min.css';
import '../src/css/odometer.min.css';
// import '../src/css/swiper.min.css';
// import '../src/css/bootstrap.min.css';
import '../src/css/style.css';
import Contact from './Components/Contact/Contact';
import About from './Components/About/About';
import './App.css';
import Gallery from './Components/Gallery/Gallery';
import GalleryAr from './Components/Gallery/GalleryAr';

import News from './Components/News/News';
import News1 from './Components/News/News1';
import News2 from './Components/News/News2';
import News3 from './Components/News/News3';
import News4 from './Components/News/News4';
import News5 from './Components/News/News5';
import News6 from './Components/News/News6';
import ContactAr from './Components/Contact/ContactAr';
import AboutAr from './Components/About/AboutAr';
import NewsAr from './Components/News/NewsAr';
import News1Ar from './Components/News/News1Ar';
import News2Ar from './Components/News/News2Ar';
import News3Ar from './Components/News/News3Ar';
import News4Ar from './Components/News/News4Ar';
import News5Ar from './Components/News/News5Ar';
import News6Ar from './Components/News/News6Ar';

function App() {
  
  useEffect(() => {
    localStorage.setItem('lang','eng') 
     }, []);
      const [ar, setAr] =  useState(localStorage.getItem('lang') ? localStorage.getItem('lang') : "eng");


  return (
    <>
    {  ar === 'eng' && < Navbar setAr={setAr}/>}
    {  ar === 'ar' && < NavbarAr setAr={setAr}/>}

<Routes>
{ar ==='eng'&&<><Route path='/' element={<Home/>}/>
<Route path='/contact' element={<Contact/>}/>
<Route path='/about' element={<About/>}/>
<Route path='/gallery' element={<Gallery/>}/>
<Route path='/news' element={<News/>}/>
<Route path='/news1' element={<News1/>}/>
<Route path='/news2' element={<News2/>}/>
<Route path='/news3' element={<News3/>}/>
<Route path='/news4' element={<News4/>}/>
<Route path='/news5' element={<News5/>}/>
<Route path='/news6' element={<News6/>}/></>}

{ar ==='ar'&&
 <> <Route path='/' element={<HomeAr/>}/>
 <Route path='/contact' element={<ContactAr/>}/>
 <Route path='/about' element={<AboutAr/>}/>
 <Route path='/gallery' element={<GalleryAr/>}/>
 <Route path='/news' element={<NewsAr/>}/>
 <Route path='/news1' element={<News1Ar/>}/>
 <Route path='/news2' element={<News2Ar/>}/>
 <Route path='/news3' element={<News3Ar/>}/>
 <Route path='/news4' element={<News4Ar/>}/>
 <Route path='/news5' element={<News5Ar/>}/>
 <Route path='/news6' element={<News6Ar/>}/></>}
</Routes>
{  ar=== 'eng'&& <Footer /> }
{  ar=== 'ar'&& <FooterAr /> }

    </>
  );
}

export default App;
