import React,{useEffect,useState} from 'react';
import loading from '../Images/preloader.gif';
import slide22 from '../Images/slide01 (1).jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Fade from "react-reveal/Fade";

export default function Contact() {
  const [name, setName] = useState('');
	const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [subject, setSubject] = useState('');

	const [message, setMessage] = useState('');
  const handleSubmit = (event) => {
		event.preventDefault();
		const data = {
		  name,
		  email,
      phone,
      subject,

		  message
		};

		sendData(data);
	  }
    const sendData = (data) => {
		axios.post('https://a-s-properties.onrender.com/contact/sendMessage', data)
		  .then(response => {
			console.log(response.data);
            swal({
                title: "Good job!",
                text: "Your data has been sent successfully!",
                icon: "success",
                button: {
                  text: "OK",
                  value: true,
                },
              }).then((value) => {
                if (value) {
                  window.location.reload();
                }
              });
		  })
		  .catch(error => {
			console.log(error);			swal("Oops!", "Please try again!", "error");

		  });
	  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>

{/* <div class="preloader">
  <div class="layer"></div>

  <div class="inner">
    <figure><img src={loading} alt="Image"/></figure>
    <p><span class="text-rotater" data-text="As Properties | Loading">Loading</span></p>
  </div>
  
</div> */}

<header class="page-header" data-stellar-background-ratio="1.15">
	<div class="container">
		<h1>Contact Us</h1>
	  <Fade bottom duration={1000} delay={500}> 	<p>We would love to hear from you! If you have any questions, inquiries, or would like to schedule a visit to our properties.</p></Fade>
		  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to='/' style={{textDecoration:'none'}}>Home</Link></li>
    <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
  </ol>
	</div>

</header>
<section class="contact" style={{padding:'40px 0'}}>
  <div class="container">
    <div class="row align-items-center">
   	<div class="col-lg-6 wow fadeInUp"> <b>01</b>
     <Fade bottom duration={1000} delay={500}>  <h4><span>A.S.</span>Properties</h4></Fade>
     <Fade bottom duration={1000} delay={500}>    <small>FEEL FREE TO CONTACT US</small></Fade> </div>
      
        <div class="col-lg-3 col-md-6 wow fadeInUp"> 
        <address>
        <Fade bottom duration={1000} delay={500}>  	<strong>Visit Us</strong></Fade>
        <Fade bottom duration={1000} delay={500}> 	<p> villa 71, fifth settlement, third district,<br/>
          first neighborhood - Cairo, Egypt</p></Fade>
        </address>
	   </div>
    
        <div class="col-lg-3 col-md-6 wow fadeInUp"> 
        <address>
        <Fade bottom duration={1000} delay={500}> 	<strong>Say Hello</strong></Fade>
        <Fade bottom duration={1000} delay={500}>  	<p ><a href="mailto:aspropertieseg@gmail.com" style={{color:'#000',textDecoration:'none'}}>aspropertieseg@gmail.com</a><br/>
         <a style={{color:'#000',textDecoration:'none'}} href="tel:0 109 669 9069">+2 0 109 669 9069</a>
         <br />
         <a style={{color:'#000',textDecoration:'none'}} href="tel: 01003660035">+2 0 100 366 0035</a>
        
         </p></Fade>
        </address>
	   </div>
    
	  </div>

    <div class="row align-items-center">
        <div class="col-lg-6">
        	 <div class="map">
          <div class="pattern-bg" data-stellar-ratio="1.03"></div>
        
          <div class="holder" data-stellar-ratio="1.07"> 
		<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3455.173380389543!2d31.43832301511411!3d30.00317758189736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAwJzExLjQiTiAzMcKwMjYnMjUuOCJF!5e0!3m2!1sen!2seg!4v1685533938617!5m2!1sen!2seg"  allowfullscreen></iframe>
         </div>

        </div>
      
        </div>
     
        <div class="col-lg-6">
        	<div class="contact-form">	
			 <form id="contact" name="contact" method="post"  onSubmit={handleSubmit}>
            <div class="form-group">
            <input type="text" name="name" id="name" autocomplete="off" required placeholder='Your Name' value={name} onChange={(e) => setName(e.target.value)}/>
             {/* <span>Your name</span> */}
          </div>
      
          <div class="form-group"> 
            <input type="text" name="email" id="email" autocomplete="off" placeholder='Your e-mail' required value={email} onChange={(e) => setEmail(e.target.value)}/>
            {/* <span>Your e-mail</span> */}
          </div>
          <div class="form-group"> 
            <input type="text" name="phone" id="phone" autocomplete="off" placeholder='Your Phone Number' required value={phone} onChange={(e) => setPhone(e.target.value)}/>
            {/* <span>Your e-mail</span> */}
          </div>
          <div class="form-group"> 
            <input type="text" name="subject" id="subject" autocomplete="off" placeholder='Subject' required value={subject} onChange={(e) => setSubject(e.target.value)}/>
            {/* <span>Subject</span> */}
          </div>
      
          <div class="form-group"> 
            <textarea name="message" id="message" autocomplete="off" placeholder='Your message' required value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
            {/* <span>Your message</span> */}
          </div>
     
          <div class="form-group">
            <button id="submit" type="submit" name="submit">
				Submit
         </button>
          </div>

        </form>
   
         <div class="form-group">
          <div id="success" class="alert alert-success wow fadeInUp" role="alert"> Your message was sent successfully! We will be in touch as soon as we can. </div>
   
        <div id="error" class="alert alert-danger wow fadeInUp" role="alert"> Something went wrong, try refreshing and submitting the form again. </div>

        </div>
    
        </div>
    
        </div>
   
   </div>
  
  </div>

</section>
</>  )
}
