import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news6 from "../Images/news6.jpg";

export default function News6Ar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
            اخبار A.S. Properties{" "}
          </h1>
          <p dir="rtl" lang="ar">
            حلول متقدمة لمنزلك الجديد
          </p>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: '15px',
              bottom: '-30px',
              background: '#9f8054',
              width: '85%',
              padding: '40px 15px',
              left: 'auto',
              margin: '0'
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              الأخبار{" "}
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center" dir="rtl" lang="ar">
              {" "}
              {/* <b>01</b> */}
              <h1 dir="rtl" lang="ar">عوامل نجاح السوق العقاري </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news6} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              السوق العقاري بما يتضمنه من خصائص يرجع نجاحه لعدة أسباب وعوامل مؤثرة يجب مراعاتها حتى يصبح السوق العقاري غنيا بالاستثمارات العقارية . وعليه نسرد فيما يلي العوامل المؤثرة في نجاح السوق العقاري ، في السطور التالية : –


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              أولا نسبة العرض والطلب وتأثيرها على السوق العقاري : –


              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              في رحلتك لفهم مفهوم العرض والطلب في القطاع العقاري، ستجد أن هناك مفاهيم يجب عليك فهما جيدا حتى تكون على إدراك تام بمكونات هذا السوق. بداية، العرض والطلب من المحركات الأساسية التي يرتكز عليها اقتصاد السوق العقاري فكلما توافقت نسبة العرض مع الطلب كلما زادت قوة السوق العقاري والعكس كذلك.


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              تعريف الطلب : ويعرف الطلب على العقارات بأنه هو عدد الوحدات المطلوبة أو نسبة إقبال المشترين على الوحدات العقارية بمختلف قطاعاتها.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              نوعيات الطلب : وهنا ستجد أن الطلب ينقسم إلى ثلاثة أنواع:


              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              الطلب الفعال أو المؤثر : هذا النوع تعبر عنه القوة الشرائية في السوق وهو يعد جزء أساسي من تعريف الطلب، وبتطبيق ذلك المفهوم على العقارات، فنجد أنه متمثل في إجمالي الطلب المرغوب على الوحدات العقارية أو الطلب السابق قبل أن يتفاعل العملاء مع السوق.

              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              الطلب المتحقق : وهو الطلب الموجود بعد تفاعل العملاء مع السوق ، وهو يختلف عن الطلب السابق حيث يتأثر ببعض التغيرات مثل قلة نسبة المعروض وانخفاض معدل الوحدات الشاغرة.

              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              الطلب الغير متحقق أو المتأخر : وهو يتمثل في الطلب غير المشبع أي الذي لم يستطيع السوق على إشباعه ويعرف بالطلب المكبوت لوجود طلبا قويا لكنه لم يتحقق بعد. وقوة الطلب في السوق العقارية تأتي مدفوعة بالزيادة السكانية والطفرة الاقتصادية التي تعيشها المنطقة، فضلاً عن توسع رقعة مساحة المدن مدعومة بنهضة عمرانية شاملة.


              </p>

              <h3 style={{ color: "#9f8054", textAlign: "justify" }} dir="rtl" lang="ar">
              تعريف العرض : 
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              يتمثل العرض في القطاع العقاري في كمية المساحات المعروضة تجارية كانت أو سكنية وذلك في كافة القطاعات، وبأسعار مختلفة، فنجد أن العرض هنا ينقسم إلى قسمين :
              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              العرض الكلي على المدى الطويل : ويتمثل العرض الكلي على المدى الطويل هنا في العلاقة بين الاسعار أو الايجارات وإجمالي عدد الوحدات او عدد الامتار المربعة المعروضة على المدى الطويل، ولكنه غير مفيد عند تحليل السوق العقاري لصعوبة تنفيذه. <br /> العرض الكلي على المدى القصير : ويشير إلى إجمالي ما يعرضه السوق أو إجمالي المخزون العقاري عند نقطة معينة من الزمن. السوق العقاري قد يتمتع بثبات المخزون لفترة زمنية طويلة قياسا بالأسواق الأخرى نتيجة لتأخر البناء حيث يحتاج البناء لفترة طويلة من التخطيط والتطوير، ولهذا فإن تأخر البناء لن يؤثر في أسعار الإيجارات التي ستظل عند حد معين لفترة طويلة. مثالاً لذلك : لو افترضنا زيادة إيجارات الوحدات السكنية بنسبة 20% غدا، فإن إجمالي المساحات المتوفرة من الوحدات ستظل ثابتة كما هي لفترة طويلة قبل أن يستجيب السوق لهذه الزيادة القوية في الإيجارات إلى حين القيام ببناء وحدات جديدة.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              ثانيا: التنوع بين أنواع الاستثمار العقاري يزيد من قوة السوق :


              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              من أهم عوامل نجاح السوق العقاري لبلد ما هو تنوع استثماراته العقارية بين أنواع الاستثمار العقاري الثلاثة، مع التركيز على الاستثمار في النوع الأكثر إنتاجية أو الأكثر جذبا للإيرادات بالنسبة لهذا البلد إضافة إلى المحاولات المستمرة لتطوير باقي أنواع الاستثمار الأخرى. ويندرج تحت مصطلح الاستثمار العقاري أنواعا ثلاثة من الاستثمارات، وهي :


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              الاستثمار في الأراضي والعقارات <br />
              التطوير العقاري <br />
              الاستثمار في عقارات الدخل السنوي 
              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              إن هذه الأنواع الثلاثة هم أساس الاستثمارات العقارية في القطاع عقاري في أي بلد من البلدان. ومن أهم ما يميز تلك الأنواع عن بعضها هو درجة المخاطرة التي يتسم بها كل نوع ومدة الاستثمار ونسبة العائد السنوي الذي يخلفه كل نوع.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              فنجد أن النوع الأول يتميز بنوع عالي من المخاطرة مع مدة استثمار قصيرة وتحقيق عائد سنوي مرتفع والمثال الأكبر على هذا النوع هو الاستثمار في الأراضي الخام والمضاربة في أسعارها، وتبلغ الأرباح العائدة من وراء هذا النوع من الاستثمار إلى أكثر من 200% سنويا وذلك وسط أكبر نشاط يشهده القطاع.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              أما النوع الثاني من الاستثمار فيمتاز بنسبة مخاطرة متوسطة، مدة استثمار متوسطة، وعائد سنوي متوسط أيضا، وأبرز مثال لهذا النوع هو التطوير من المشاريع العقارية الموجودة وبناء المشاريع العقارية الجديدة بجميع أنواع وحداتها وتصل أرباحه السنوية إلى 30%.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              أما النوع الثالث والأخير فيتميز بتدني نسبة المخاطرة فيه ولكن من جانب آخر ولكنه أيضاً منخفض العوائد وطويل المدى وأبرز مثال عليه هو أن يقوم شخص بتأجير عقار ما يدر دخلا سنويا عليه، وتبلغ العوائد السنوية لهذا النوع إلى أكثر من 7 %.


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              ثالثاً : إتباع المستثمرين لأساليب التسويق العقاري الصحيحة :


              </h3>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ينبغي علينا هنا أن نفرق بين مفهوم التسويق العقاري ومفهوم الترويج العقاري فالكثير يخلطون بين هذين المفهومين فيعتقدون بأنهما الشيء ذاته، بل أكثر من ذلك فإن هناك من يعتقد اعتقادا خاطئا بأن التسويق جزء من الترويج للعقار، ولكن الصحيح هو العكس تماما. فعملية الترويج ما هي إلا جزءا وعنصرا واحدا من ضمن العناصر الأربعة للتسويق العقاري. وحسب المفهوم الذي أورده البروفيسور جيروم ماكارتي أستاذ التسويق في جامعة متشيغان الأميركية عام 1960 للمزيج التسويقي الذي بات مشهورا على مستوى العالم، والواجب استخدامه لنجاح عملية التسويق في القطاع العقاري، فإن عملية التسويق تتضمن أربعة محاور وتبدأ جميعها بحرف ال P وهي الأدوات التنسيقية اللازمة لسياسة ناجحة:


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              المنتج (المنتج العقاري) : ويعني ما يعرضه السوق نفسه من منتجات عقارية باختلاف أنواعها، وما يرتبط بالمنتج من خدمات ومرافق ومميزات يتمتع بها المشتري عند شرائه لهذا المنتج.

                <br />
                المكان : لتطبيق عامل المكان على الاستثمار في السوق العقاري فنجد أن المستثمر يجب أن يكون على وعي كامل وكبير بالمكان الذي يتم الاستثمار فيه، من حيث مميزات موقع العقار ودراسة نسبة نجاح المنتج العقاري الذي يتم التسويق له.

                <br />
                الترويج : وهو يعني الإعلان عن السلعة العقارية والترويج لها بكافة طرق الجذب الممكنة لتذكير السوق المستهدف بتواجد المنتج العقاري وفوائده.

                <br />
                السعر : وهذا المحور يتمثل في سعر المنتج العقاري إضافة إلى ما يرتبط به من رسوم واجبة لاستكمال انعقاد الصفقة العقارية.


              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
