import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";

export default function News1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties News</h1>
          <p>Advanced Solutions to your new home</p>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              A.S. Properties News
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center">
              {" "}
              {/* <b>01</b> */}
              <h1>
                Tips for the owner to choose the right real estate broker.
              </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news1} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }}>
                If you own one or more properties and do not have the time to
                manage them, or if you lack sufficient expertise, it is best to
                entrust the task to a specialized real estate broker who
                provides real estate services. This is due to the additional
                time and effort required by the process. Therefore, you should
                search for an experienced broker, and most importantly, ensure
                that they follow a computerized system in their real estate
                work.
              </p>
              <p style={{ textAlign: "justify" }}>
                {" "}
                Whereas the property owner must exercise caution in selecting a
                real estate broker to manage their properties, there are several
                tasks that the broker must adhere to before being chosen by the
                owner. These tasks are essential to assess the broker's ability
                to work with optimal capabilities and high quality. They
                include:
              </p>
              <h3 style={{ color: "#9f8054" }}>
                The process of managing properties by the real estate broker
              </h3>
              <p style={{ textAlign: "justify" }}>
                The real estate broker builds their working style according to
                their experience to contribute to the development of the
                services they provide to their clients. Many real estate brokers
                who specialize in property management choose to collaborate
                effectively with the Asas Real Estate platform. This is due to
                the comprehensive package of real estate programs and systems it
                offers, with multiple uses and benefits provided to the
                establishment's employees.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                The real estate broker's ability to determine appropriate rental
                rates
              </h3>
              <p style={{ textAlign: "justify" }}>
                As an owner, you may need guidance in the process of determining
                rental rates for your real estate units. The rental price of
                each property unit varies according to several different
                factors. This is where the role of the real estate broker comes
                in, to determine the appropriate rental rate.{" "}
              </p>

              <h3 style={{ color: "#9f8054" }}>
                Monitoring the property management activities for the owner
              </h3>
              <p style={{ textAlign: "justify" }}>
                The process of managing lease contracts, handling collection
                tasks, documenting all financial transactions and movements, is
                considered one of the most important tasks of the real estate
                broker. In addition, Asas Real Estate provides a dedicated
                online portal for property owners called 'Owners Portal.' This
                portal serves as an intermediary between owners and real estate
                brokerage firms, allowing owners to closely monitor all
                information related to their properties. They can track rented
                or vacant units, get insights into the number of managed
                properties, and regularly check their current balance.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                Collecting monthly rent payments on time
              </h3>
              <p style={{ textAlign: "justify" }}>
                We all know that the tenant's commitment to paying rent can
                sometimes be challenging. The real estate broker establishes a
                set of controls to ensure the timely collection of the
                agreed-upon rent from the tenant. Asas Real Estate provides a
                dedicated online portal for tenants called 'Tenants Portal,'
                which acts as an electronic intermediary between the real estate
                brokerage firm and the tenant. Through this portal, all
                financial transactions can be recorded, and the tenant can be
                reminded of upcoming payment deadlines. Additionally, the
                platform allows for sending email notifications to remind the
                tenant of rent payment before or after the specified due date.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                The ability to market and advertise properties
              </h3>
              <p style={{ textAlign: "justify" }}>
                Having extensive experience in marketing and managing real
                estate, the real estate broker knows the appropriate time and
                place to advertise properties. They understand how to craft
                compelling advertising content, which is an important advantage
                provided by the Asas Real Estate platform. It offers a
                specialized real estate marketplace platform for advertising
                properties.
              </p>

              <h3 style={{ color: "#9f8054" }}>
              Finding suitable tenants
              </h3>
              <p style={{ textAlign: "justify" }}>
              Real estate brokers have sufficient experience in identifying good tenants, as they pay attention to all the details related to the tenant's personality, as well as their legal and security safety, and verifying their financial ability to pay the rent.
              </p>

              <h3 style={{ color: "#9f8054" }}>
              Managing the relationship between the tenant and the owner
              </h3>
              <p style={{ textAlign: "justify" }}>
              Managing the relationship in all its aspects between the tenant and the owner, the real estate broker serves as an ideal intermediary to ensure the organization of rent payments and all financial transactions. They handle regular and emergency maintenance, including receiving maintenance requests and coordinating with service providers to resolve them. Additionally, they manage any situation that requires finding suitable and effective solutions.              </p>
           
              <h3 style={{ color: "#9f8054" }}>
              Building strong relationships with service providers
              </h3>
              <p style={{ textAlign: "justify" }}>
              You will inevitably need maintenance workers through service providers for various tasks, and as a property owner, it can be challenging to find someone who performs their work with high quality and at the best possible price. This is where the real estate agent takes on the supervision tasks related to maintenance. The availability of a number of skilled tasks performed by the real estate agent, utilizing innovative technological techniques, is a convincing and sufficient reason for you as a property owner to collaborate with a regular and diligent agent.              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
