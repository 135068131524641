import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news2 from "../Images/news2.jpg";

export default function News2Ar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     <header class="page-header" data-stellar-background-ratio="1.15">
	<div class="container">
		<h1  dir="rtl" lang="ar"> اخبار A.S. Properties </h1>
		<p  dir="rtl" lang="ar">حلول متقدمة لمنزلك الجديد</p>
		  <ol class="breadcrumbAr"  dir="rtl" lang="ar" style={{    position: 'absolute',
   right: '15px',
   bottom: '-30px',
   background: '#9f8054',
   width: '85%',
   padding: '40px 15px',
   left: 'auto',
   margin: '0'}}>
    <li class="breadcrumbAr-item"><Link to='/' style={{textDecoration:'none'}}>الرئيسية</Link></li>
    <li class="breadcrumbAr-item active" aria-current="page">الأخبار </li>
  </ol>
	</div>

</header>


      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center" dir="rtl" lang="ar">
              {" "}
              {/* <b>01</b> */}
              <h1 dir="rtl" lang="ar">
              مستقبل السوق العقاري في مصر لعام 2023

              </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news2} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              يعتبر الاستثمار العقاري واحد من أهم وأبرز أنواع الاستثمارات، والذي يتجه إليه الكثير من المستثمرين نظرا لأنه الأكثر ربحا واستقرارا.
              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                {" "}
                وهذا لأن امتلاك عقار أي كان نوعه يعد واحد من أهم الأهداف التي يسعى إليها المصريين، نظرا لكم المميزات التي تعود عليهم ويستفيدون بها سواء كانوا يمتلكون وحدة سكنية للعيش فيها، أو تأجيرها للاستفادة من الدخل الثابت الذي يعود عليه.




              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ومؤخرا مع الأحداث التي يشهدها العالم والتغيرات الاقتصادية وغيرها، بدأ يتبادر الكثير من الأسئلة لدى المستثمرين حول مستقبل السوق العقاري في مصر عام 2023، هل سيتأثر بسلب أم يواجه كل هذه التحديات والصعوبات، وكثير من الأسئلة التي سنجاوب عليها في هذا المقال.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar"> 
              مم يتكون سوق العقارات المصري؟
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              يعتقد الكثير أن السوق العقاري ما هو إلا عبارة عن مباني سكنية ووحدات أي كان نوعها، إلا أن هذا يعد جزء منه وليس كله، فالسوق العقاري يضم أنواع مختلفة جميعها ترتبط بالعديد من الصناعات التي تعتمد عليها وترتبط بها ارتباطا وثيقا سواء كانت صناعة الحديد والأسمنت، أو صناعة الدهانات ومواد التشطيب، أو صناعة الأخشاب و الأرضيات، وينقسم السوق العقاري المصري إلى عدة أنواع منها:


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">1- العقارات السكنية
</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              وهي عبارة عن وحدات سكنية متنوعة سواء كانت شقق، فلل، ومختلف أنواع العقارات، وتعتبر العقارات السكنية من أهم وأشهر العقارات والأكثر إقبالا من قبل المستثمرين.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">2- العقارات التجارية
</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              وهي تخص كل العقارات التي تقوم على عملية البيع والشراء، سواء كانت مطاعم، مقاهي، محال تجارية وغيرها، وتعتبر العقارات التجارية من أقوى أنواع الاستثمار العقاري بعد الاستثمار العقاري السكني، وهذا لأنا تحقق أعلى عائد استثماري.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              3- العقارات الإدارية

              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              العقارات الإدارية هي عبارة عن مجموعة من الوحدات يتم تحويلها إلى مقرات إدارية، أو طبية، والتي يتم تصميمها بمواصفات معينة كي تتناسب مع طبيعة المجال، وتتميز العقارات بأن الاستثمار بها يمثل دخل ثابت يستفيد منه المستثمر العقاري.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">4- العقارات الصناعية
</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              وهي عبارة عن كل ما يتعلق بالمصانع والورش والأغراض الصناعية، واللي تتزايد قيمتها مع مرور السنوات، بالرغم من أنها أقل عائد من العقارات السكنية  والتجارية.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              أبرز مميزات السوق العقاري المصري
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              يتمتع سوق العقارات المصري بالعديد من المميزات التي تجعله محط أنظار المستثمرين، وعامل جذب لأصحاب رؤوس الأموال، وبالرغم من الأوضاع التي تضرب ليس فقط سوق العقارات وإنما مختلف أنواع الاستثمارات، إلا أن سوق العقارات دائما ما يكون أقل ضررا لهذه التغيرات، ومن أهم هذه المميزات:


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">1- القيمة المرتفعة لسوق العقارات
</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              يعتبر الاستثمار العقاري من أهم وأقوى أنواع الاستثمارات، والذي تقوم عليه الاقتصادات العالمية بشكل كبير، حيث تصل قيمة العقارات على مستوى العالم إلى ما يقرب من 2 تريليون دولار أمريكي، بينما يعتمد اقتصاد الصين على الاستثمار العقاري بشكل كبير يصل إلى 21% من السوق العالمي لتصبح في مقدمة الدول التي تعتمد على الاستثمار العقاري، بينما تأتي أمريكا في المرتبة الثانية، هذا بجانب الكثير من الدول الأوروربية ومنها ألمانيا وبريطانيا وإيطاليا وغيرها.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              2- الحماية من التضخم

              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              دائما ما يخلف التضخم آثار اقتصادية سلبية على كافة الأسواق ومختلف الاستثمارات، إلا أن السوق العقاري يعد من أكثر أنواع الأسواق مجابهة للتضخم، وهذا لأن العقار عبارة عن أصل ثابت ومجمد لا يتغير ولا يشهد أي خسارات، حيث أن العملات المحلية تفقد جزء من قيمتها كلما ازداد التضخم، ولكن العقار تزيد قيمته بموازاة نسبة التضخم.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              3- العقار مخزن آمن للقيمة والأصول
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              دائما ما يذهب الكثير ممن يريدون الحفاظ على أموالهم إلى الاستثمار في العقارات، وهذا لأن العقارات تعد من أهم أنواع الاستثمارات مواجهة للتغيرات الاقتصادية، فهي ثابتة دائما، بل ويتزايد سعرها وقيمتها مع مرور السنوات.


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">4- دخل ثابت
</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              كثيرا ما يتجه المستثمرين إلى الاستثمار في العقارات، وذلك من خلال شراء وحدة والانتظار عليها لبعض الوقت ثم بيعها بسعر أغلى، والبعض الآخر يقوم بتأجير العقار مما يضمن له دخل شهري وسنوي ثابت ومضمون.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              مستقبل السوق العقاري في مصر لعام 2023

              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              يواجه العالم الكثير من التغيرات التي أثرت على مختلف القطاعات بشكل سلبي، فما بين جائحة كورونا التي أثرت على الدخل لكثير من الفئات على مستوى العالم، وبين الحرب الروسية والأوكرانية التي أثرت على الاقتصاد العالمي، كل هذا جعل الكثير يتساءلون حول مستقبل السوق العقاري العالمية وتحديدا المصري.


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              حيث يتوقع الخبراء الإقبال على العقارات المتوسطة مثل الشقق السكنية تزامنا مع تقليل الطلب على العقارات من نوع الفلل والقصور، وذلك كما ذكرنا نتيجة لانخفاض دخل الفرد على المستوى العالم، كما يتوقع الخبراء زيادة في أسعار العقارات وذلك نتيجة لزيادة أسعار مواد البناء المختلفة.


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ومن الجدير بالذكر أن هناك اهتمام متزايد بالعقارات في مصر من قبل المستثمرين المحليين والأجانب، وخاصة في المدن الكبرى مثل القاهرة والإسكندرية والجيزة والعاصمة الإدارية الجديدة. ومن المتوقع أن تشهد هذه المدن زيادة في عدد المشروعات العقارية والتطوير العمراني في الفترة القادمة.


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              بالإضافة إلى ذلك، يتجه العديد من المستثمرين إلى العقارات التجارية مثل المكاتب والمحلات التجارية والفنادق، وذلك لاستغلال الطلب المتزايد على هذه العقارات.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              وبشكل عام، يمكن القول إن السوق العقاري في مصر لا يزال يعد وجهة استثمارية جيدة، ومن المتوقع أن يستمر في النمو في الفترة القادمة، خاصة مع الاهتمام المتزايد بالتنمية العمرانية في البلاد وتنوع الفرص الاستثمارية.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              الخطوات الأساسية التي يجب اتباعها عند البدء في الاستثمار العقاري

              </h3>

              <ul class="dot-list" style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                <li>
                يجب على المستثمر تحديد أهداف الاستثمار، سواء كانت للحصول على دخل مستمر من استئجار العقارات أو للحصول على مكاسب رأسمالية عند بيع العقارات في المستقبل.

                </li>
                <li>
                يجب تحديد الميزانية المتاحة لدى المستثمر لشراء العقارات، ويجب أيضا التأكد من القدرة على تحمل تكاليف الشراء والصيانة والإدارة وفقًا لأهداف الاستثمار.

                </li>
                <li>
                يجب البحث عن العقارات المناسبة لأهداف الاستثمار الخاصة بالمستثمر، والتأكد من أنها تتوافق مع ميزانيته ومتطلباته الأخرى، كما يمكن للمستثمر البحث عبر الإنترنت أو الحصول على مساعدة من وكالات العقارات المحلية.

                </li>
                <li>
                يجب التحقق من حالة العقار ومعرفة ما إذا كان يحتاج إلى أي صيانة أو ترميمات قبل الشراء.

                </li>
                <li>
                يجب على المستثمر التفاوض على السعر مع البائع أو الوكالة العقارية، والتأكد من أن السعر يتوافق مع قيمة العقار وحالته.

                </li>
                <li>
                يجب التأكد من أن جميع الأوراق القانونية للعقار موجودة وصحيحة، وأن لا يوجد أي مشاكل قانونية تؤثر على عملية الشراء.

                </li>
                <li>
                بعد الشراء، يجب على المستثمر إدارة العقار بشكل جيد ومتابعة دفع الإيجارات وصيانة العقار وتطويره لتحقيق العائد المرجو من الاستثمار.

                </li>
              </ul>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar"> 
              الصعوبات والتحديات التي تواجه السوق العقاري المصري

              </h3>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              مع زيادة سعر الدولار في الفترة الأخيرة، وقيام البنوك برفع الفائدة على الشهادات الاستثمارية إلى 25%، توجه الكثير من المستثمرين إلى إلى البنوك وتراجعوا عن الاستثمار في قطاع العقارات، ولكن أكد الخبراء في المجال العقاري أن عوائد الشهادات تؤثر على المدى القريب فقط، على عكس الاستثمار العقاري الذي يتمتع بعائد على المدى البعيد.


              </p>

              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ونتيجة لهذا الارتفاع في سعر الدولار، أصبح التضخم والارتفاع في أسعار المواد الخام من التحديات الرئيسية التي تواجه السوق العقاري المصري، حيث يؤثر ذلك على تكاليف الإنشاء والصيانة والتأجير ويؤدي إلى ارتفاع أسعار العقارات.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ومن التحديات التي تواجه سوق العقارات هو تغير الأذواق والاحتياجات، حيث يجب مواكبة التغيرات في الأذواق والاحتياجات السكانية في تصميم العقارات و تطويرها، العمل على تنفيذ مشاريع ذات تصاميم عصرية وحديثة كي تلائم مختلف الرغبات والأذواق.

</p>
           
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              العوامل المؤثرة على سعر العقار
              </h3>

              <ul class="dot-list" style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                <li>
                يعتبر العرض والطلب من العوامل الرئيسية التي تؤثر على أسعار العقارات، وهذا لأن سعر العقار يرتفع عندما يزداد الطلب عليه، وينخفض عندما يقل الطلب عليه.


                </li>
                <li>
                يؤثر الموقع على سعر العقار، حيث تزداد قيمة العقار عندما يقع في موقع حيوي قريب من كافة الخدمات والطرق والمواصلات.
                </li>
                <li>
                تزداد قيمة وسعر العقار كلما كان ذو جودة عالية وحالة جيدة.


                </li>
                <li>
                تؤثر التغيرات الاقتصادية على أسعار العقارات.


                </li>
                <li>
                التغيرات الديموغرافية لها دور تأثير فعال على أسعار العقارات، مثل النمو السكاني وتغير الأذواق والاحتياجات السكانية.

                </li>
               
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
