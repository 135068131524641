import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";
import news2 from "../Images/news2.jpg";
import news3 from "../Images/news3.jpg";
import news4 from "../Images/news4.JPG";
import news5 from "../Images/news5.jpg";
import news6 from "../Images/news6.jpg";
import Fade from "react-reveal/Fade";

export default function News() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>

<header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties News</h1>
          <Fade bottom duration={1000} delay={500}>    <p>Advanced Solutions to your new home</p></Fade>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to='/' style={{textDecoration:'none'}}>Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
               A.S. Properties News
            </li>
          </ol>
        </div>
      </header>



<section class="recent-posts" style={{padding:'60px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp">
              {" "}
              <b>01</b>
              <Fade bottom duration={1000} delay={500}>  <h4>
                <span>A.S.</span> Properties News
              </h4></Fade>
              <small>Read Our News </small>{" "}
            </div>
             <div class="col-lg-4 wow fadeInUp" data-wow-delay="0s">
           <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news1' >
                  <img src={news1} alt="Image" />{" "}</Link>
                </figure>
                <span>2, August 2022</span>
                <h6>
                <Link to='/news1' style={{ textDecoration: "none" }}>
                 Tips for the owner to choose the right real estate broker.
                  </Link>
                </h6>
                <Link to='/news1'>
                <button className="readMore">Read More  &nbsp;<i class="fas fa-caret-right"></i></button></Link>
              </div>
            </div>

            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.10s">
           <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news2' >  <img src={news2} alt="Image" />{" "}</Link>
                </figure>
                <span>17, November 2022</span>
                <h6>
                   <Link to='/news2' style={{ textDecoration: "none" }}>
               What is the future of the real estate market in Egypt for the year 2023?
                  </Link>
                </h6>
                <Link to='/news2' >     <button className="readMore">Read More  &nbsp;<i class="fas fa-caret-right"></i></button></Link>

              </div>
            </div>

            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
         <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news3' >  <img src={news3} alt="Image" />{" "}</Link>
                </figure>
                <span>15, January 2021</span>
                <h6>
                <Link to='/news3'  style={{ textDecoration: "none" }}>
                  Three types of profitable real estate investment.                  </Link>
                </h6>
                <Link to='/news3' ><button className="readMore">Read More  &nbsp;<i class="fas fa-caret-right"></i></button></Link>

              </div>
            </div>


          
          </div>
            <div class="row" style={{paddingTop:'4%'}}>
            
          
            
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
             <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news4' >     <img src={news4} alt="Image" />{" "}</Link>
                </figure>
                <span>15, January 2021</span>
                <h6>
                <Link to='/news4' style={{ textDecoration: "none" }}>
                  Types of residential apartments that should be familiarized with.                 </Link>
                </h6>
                <Link to='/news4' >  <button className="readMore">Read More  &nbsp;<i class="fas fa-caret-right"></i></button></Link>

              </div>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
            <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news5' >    <img src={news5} alt="Image" />{" "}</Link>
                </figure>
                <span>15, January 2021</span>
                <h6>
                <Link to='/news5'  style={{ textDecoration: "none" }}>
                  Real estate appraisal  terminology in details.                </Link>
                </h6>
                <Link to='/news5' ><button className="readMore">Read More  &nbsp;<i class="fas fa-caret-right"></i></button></Link>

              </div>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
           <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news6' >   <img src={news6} alt="Image" />{" "}</Link>
                </figure>
                <span>15, January 2021</span>
                <h6>
                <Link to='/news6'  style={{ textDecoration: "none" }}>
                  Factors for the success of the real estate market.                </Link>
                </h6>
                <Link to='/news6' ><button className="readMore">Read More  &nbsp;<i class="fas fa-caret-right"></i></button></Link> 

              </div>
            </div>
          </div>
        </div>
      </section>
</>  )
}
