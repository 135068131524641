import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news6 from "../Images/news6.jpg";

export default function News6() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties News</h1>
          <p>Advanced Solutions to your new home</p>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              A.S. Properties News
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center">
              {" "}
              {/* <b>01</b> */}
              <h1>Factors for the success of the real estate market. </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news6} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }}>
                The real estate market, with its encompassing characteristics,
                owes its success to several reasons and influential factors that
                must be considered to make it rich in real estate investments.
                Therefore, we will outline the factors that contribute to the
                success of the real estate market in the following lines:
              </p>

              <h3 style={{ color: "#9f8054" }}>
                First, the supply and demand ratio and its impact on the real
                estate market:{" "}
              </h3>
              <p style={{ textAlign: "justify" }}>
                In your journey to understand the concept of supply and demand
                in the real estate sector, you will find that there are key
                concepts you must grasp to have a complete understanding of the
                components of this market. Firstly, supply and demand are
                fundamental drivers that form the basis of the real estate
                market's economy. The stronger the alignment between supply and
                demand, the stronger the real estate market becomes, and vice
                versa.
              </p>

              <p style={{ textAlign: "justify" }}>
                Definition of demand: Demand for real estate is defined as the
                number of units requested or the level of buyer interest in real
                estate units across various sectors.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                Types of demand: Here you will find that demand can be divided
                into three types:
              </h3>
              <p style={{ textAlign: "justify" }}>
                Effective or influential demand: This type represents the
                purchasing power in the market and is an essential part of the
                definition of demand. When applying this concept to real estate,
                we find that it refers to the total desired demand for real
                estate units or the pre-existing demand before customers
                interact with the market.
              </p>

              <p style={{ textAlign: "justify" }}>
                Realized demand: This is the demand that exists after customers
                interact with the market, and it differs from the previous
                demand as it is influenced by certain changes such as a decrease
                in the supply ratio and a decline in the vacancy rate of units.
              </p>

              <p style={{ textAlign: "justify" }}>
                Unrealized or delayed demand: This refers to the unsatisfied
                demand, meaning the demand that the market has not been able to
                fulfill yet. It is known as latent demand, where there is a
                strong demand that has not been realized yet. The strength of
                demand in the real estate market is driven by population growth,
                economic boom in the region, as well as the expansion of urban
                areas supported by comprehensive urban development.
              </p>

              <h3 style={{ color: "#9f8054", textAlign: "justify" }}>
                Definition of Supply: 
              </h3>
              <p style={{ textAlign: "justify" }}>In the real estate sector, supply refers
                to the quantity of available commercial or residential spaces
                across all sectors and at different prices. Here, supply can be
                divided into two categories:</p>
              <p style={{ textAlign: "justify" }}>
                Total Long-Term Supply: The total long-term supply represents
                the relationship between prices or rents and the total number of
                units or square meters available over the long term. However, it
                is not useful for analyzing the real estate market due to its
                difficulty in implementation. <br /> Total Short-Term Supply: It refers
                to the total inventory or stock of properties available in the
                market at a specific point in time. The real estate market may
                have a stable inventory for a long period compared to other
                markets due to construction delays, as construction requires a
                lengthy process of planning and development. Therefore,
                construction delays will not immediately impact rental prices,
                which will remain at a certain level for an extended period. For
                example, if we assume a 20% increase in rental prices tomorrow,
                the total available units will remain constant for a long time
                before the market responds to this strong increase in rents by
                constructing new units.
              </p>

              <h3 style={{ color: "#9f8054" }}>
              Secondly, the diversity of real estate investment types increases the strength of the market.              </h3>
              <p style={{ textAlign: "justify" }}>
              One of the most important factors for the success of a country's real estate market is the diversity of its real estate investments among the three types of real estate investment, with a focus on investing in the most productive or revenue-generating type for that country. Additionally, continuous efforts should be made to develop the other types of investments. Under the term "real estate investment," there are three types of investments, which include:
              </p>

             
              <p style={{ textAlign: "justify" }}>
          Properties Trading. <br />
Real Estates Development. <br />
Income Generating Assets.
              </p>

              <p style={{ textAlign: "justify" }}>
              These three types form the foundation of real estate investments in the real estate sector in any country. One of the key distinguishing factors between these types is the level of risk associated with each type, the investment duration, and the annual return percentage generated by each type.              </p>
<p style={{ textAlign: "justify" }}>We find that the first type is characterized by high risk, short investment duration, and a high annual return. The prime example of this type is investing in raw land and speculating on its prices, with profits exceeding 200% annually, amid significant activity in the sector.</p>
<p style={{ textAlign: "justify" }}>As for the second type of investment, it is characterized by a moderate level of risk, a medium investment duration, and an average annual return. The prominent example of this type is the development of existing real estate projects and the construction of new real estate projects of all types, with annual profits reaching 30%.</p>
<p style={{ textAlign: "justify" }}>The third and final type is characterized by low risk but, on the other hand, it also offers low returns and long-term benefits. A notable example of this type is leasing a property to generate annual income, with annual returns exceeding 7%.</p>
<h3 style={{ color: "#9f8054" }}>
Thirdly, investors should follow proper real estate marketing strategies.          </h3>

<p style={{ textAlign: "justify" }} >Here, we should differentiate between the concept of real estate marketing and the concept of real estate promotion, as many people confuse these two concepts and mistakenly believe they are the same thing. Furthermore, some even believe that marketing is just a part of promoting real estate. However, the truth is quite the opposite. The process of promotion is merely a part and one element among the four elements of real estate marketing. According to the concept introduced by Professor Jerome McCarthy, a marketing professor at the University of Michigan in 1960, regarding the marketing mix, which has become well-known worldwide and is essential for successful marketing in the real estate sector, the marketing process includes four aspects, all starting with the letter "P," which are the necessary coordinating tools for a successful strategy:</p>
           
           <p style={{ textAlign: "justify" }} >
           Product (Real Estate Product): It refers to the various real estate products offered by the market, including their different types, as well as the services, facilities, and features associated with the product that the buyer enjoys upon purchase.
           <br /> 
           Place: Applying the factor of place to real estate investment, we find that the investor needs to have a thorough understanding and knowledge of the location where the investment is made. This includes studying the advantages of the property's location and assessing the success potential of the marketed real estate product.
           <br />
           Promotion: It means advertising and promoting the real estate property through various attractive methods to remind the target market of the presence of the real estate product and its benefits.
           <br />
           Price: This aspect involves the price of the real estate product, as well as any associated fees required to complete the real estate transaction.
           </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
