import React,{useEffect} from "react";
import Integrity from "../Images/collaborate.png";
import Honesty from "../Images/honesty.png";
import Empathy from "../Images/empathy.png";
import Compassion from "../Images/kindness.png";
import Morality from "../Images/morality.png";
import Guidance from "../Images/guidance.png";
import Respect from "../Images/trust.png";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

export default function AboutAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    <header class="page-header" data-stellar-background-ratio="1.15">
	<div class="container">
		<h1  dir="rtl" lang="ar"> اقرأ عن A.S. Properties</h1>
		<Fade bottom duration={1000} delay={500}><p  dir="rtl" lang="ar">حلول متقدمة لمنزلك الجديد</p></Fade>
		  <ol class="breadcrumbAr"  dir="rtl" lang="ar" style={{    position: 'absolute',
    right: '15px',
    bottom: '-30px',
    background: '#9f8054',
    width: '85%',
    padding: '40px 15px',
    left: 'auto',
    margin: '0'
    }}>
    <li class="breadcrumbAr-item"><Link to='/' style={{textDecoration:'none'}}>الرئيسية</Link></li>
    <li class="breadcrumbAr-item active" aria-current="page"> اقرأ عن  A.S. Properties </li>
  </ol>
	</div>

</header>
 

      <section class="about-content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2 dir="rtl" lang="ar">
               إنشاءات  <span >A.S. Properties</span>
              </h2>
              <Fade bottom duration={1000} delay={500}>   <h5 dir="rtl" lang="ar">
              A.S Properties هي وكالة استشارات عقارية مقرها في مصر أسسها ويديرها مستشارون عقاريون مؤهلون تأهيلا عاليا.
              </h5></Fade>
            </div>

            <div class="col-lg-6">
            <Fade left duration={1000} delay={500}>   <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              نحن في سوق العقارات منذ أكثر من 10 سنوات. بدأنا كمستثمرين في سوق العقارات مما يضمن رضاك التام عن جودة الخدمات التي نقدمها. 
              </p></Fade>
            </div>

            <div class="col-lg-6">
            <Fade right duration={1000} delay={500}>   <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              نعمل دائما على تقديم أبحاث السوق المحدثة الكاملة والكاملة التي ستساعدك على اتخاذ قرار مدروس ودقيق عند شراء منزل جديد أو بيع أو استئجار منزل لديك بالفعل.
              </p></Fade>
            </div>

            <div class="col-lg-6 col-sm-12" style={{paddingTop:'2%'}}>
              <h4 dir="rtl" lang="ar">رؤيتنا</h4>
              <Fade left duration={1000} delay={500}>   <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              في A.S Properties، لا نبحث عن عميل لمرة واحدة، بل نسعى إلى علاقة طويلة الأمد بين الشركة وعميل راض.              </p></Fade>
            </div>
            <div class="col-lg-6 col-sm-12"  style={{paddingTop:'2%'}}>
              <h4 dir="rtl" lang="ar">مهمتنا</h4>
              <Fade right duration={1000} delay={500}>  <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              نحن هنا لمساعدتك في العثور على منزلك الجديد و / أو استثمارك، مع الأخذ في الاعتبار أنك تحصل على أفضل عائد على الاستثمار والعائد على حقوق الملكية وأكبر قيمة من الاستثمار الذي ستضعه فيه.              </p></Fade>
            </div>
         
            <section class="benefits" style={{backgroundColor:'transparent', padding:'60px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp" dir="rtl" lang="ar">
              {" "}
              <b>02</b>
              <Fade bottom duration={1000} delay={500}>   <h4 dir="rtl" lang="ar">
                قيم 
                <span> A.S Properties </span> </h4></Fade>
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0s">
              <figure>
                {" "}
                <img src={Integrity} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar">النزاهة</h6>
             
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0.05s">
              <figure>
                {" "}
                <img src={Honesty} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar">الشفافية</h6>
             
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Empathy} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar"> التعاطف</h6>
             
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Compassion} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar">الرحمة</h6>
             
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Morality} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar">مبادئ أخلاقية</h6>
           
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Guidance} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar">إرشاد</h6>
            
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Respect} alt="Image" /> <b></b>{" "}
              </figure>
              <h6 dir="rtl" lang="ar">احترام</h6>
     
            </div>
          </div>
        </div>
      </section>
      <section class="benefits" style={{backgroundColor:'transparent', padding:'0px 0'}}>
      <div className="container">
      <div className="row">
      <div class="col-12 wow fadeInUp">
              {" "}
              <b>03</b>
              <Fade bottom duration={1000} delay={500}>   <h4 dir="rtl" lang="ar">
                <span>القيادة</span> فريقنا
              </h4></Fade>
            </div>
      </div></div></section>
      <div class="col-md-6 col-sm-12">
              <blockquote style={{minHeight:'39vh'}}>
                <h4 dir="rtl" lang="ar">
                سامر أنور
                </h4>
                <strong dir="rtl" lang="ar" style={{float:'right'}}>مدير المبيعات</strong><br />
                <div style={{display:'flex', justifyContent:'end', width:'100%'}}>     <strong dir="rtl" lang="ar"  >بكالوريوس الحقوق</strong> </div><br />
<p style={{ textAlign: "justify" }} dir="rtl" lang="ar">سامر هو مستشار عقاري مؤهل للغاية ومدير مبيعات. وهو يوجه ممثلي A.S Properties حول كيفية أن تكون ذات فائدة كبيرة لعملائهم وهو دائما على اطلاع دائم بأحدث وأحدث أبحاث السوق. إن معرفته الكبيرة بالسوق في متناول عملائنا ودراسته للقانون ليست أقل فائدة لهم عندما يتعلق الأمر بالعقود والجوانب القانونية.</p>
              </blockquote>

            
            </div>
            <div class="col-md-6 col-sm-12">
              <blockquote style={{minHeight:'39vh'}}>
                <h4 dir="rtl" lang="ar">
                آنا-سيمون عادل
                </h4>
                <strong dir="rtl" lang="ar"  style={{float:'right'}}>الرئيس التنفيذي</strong> <br />
             <div style={{display:'flex', justifyContent:'end', width:'100%'}}>  <strong dir="rtl" lang="ar" >بكالوريوس هندسة - قسم العمارة</strong> </div> <br />
<p style={{ textAlign: "justify" }} dir="rtl" lang="ar">آنا هي مستشارة ومستثمرة عقارية شغوفة. تضيف دراستها للهندسة المعمارية قيمة كبيرة لعملها كمستشارة عقارية، حيث تكون قادرة على فهم الرسومات المعمارية ولديها طريقة لمساعدة العملاء على الحصول على رؤية أفضل للمكان الذي هم على وشك الاستثمار فيه.  إن البدء كمستثمر عقاري ساعدها على الارتباط باحتياجات العميل وبالتالي توفير خيارات دقيقة لتلبية تلك الاحتياجات مضيفة أفضل عائد على الاستثمار وعائد على حقوق الملكية رائعين.</p>
              </blockquote>

            
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
}
