import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news4 from "../Images/news4.JPG";

export default function News4Ar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
       <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
            اخبار A.S. Properties{" "}
          </h1>
          <p dir="rtl" lang="ar">
            حلول متقدمة لمنزلك الجديد
          </p>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: '15px',
              bottom: '-30px',
              background: '#9f8054',
              width: '85%',
              padding: '40px 15px',
              left: 'auto',
              margin: '0'
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              الأخبار{" "}
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center" dir="rtl" lang="ar">
              {" "}
              {/* <b>01</b> */}
              <h1 dir="rtl" lang="ar">
              انواع الشقق السكنية التي يجب التعرف عليها

              </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news4} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ان الاستثمار العقاري وخاصة في الشقق والوحدات بالمدن الجديدة سواء كان الغرض من الشراء هو البيع بعد فترة زمنية او كان الغرض منها التأجير فهو قرار يجب التأني فية حتي لا تقع في مشكلات بسبب عدم المعرفة والدراية الكاملة بأنواع الشقق السكنية ومدي ملائمتها للاحتياج والرغبة في قرار الشراء من البداية .


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              ولذلك قبل الإقدام علي قرار الشراء والاستثمار العقاري بوجه عام يجب ان يكون الفرد ملم بكل المعلومات وانواع الشقق المتاحة في السوق وما هو مميزات كل منها وعيوبها وما هو مميزات وحدة عن الاخري حتى يتمكن من اتخاذ القرار السليم وفق تلك المعلومات . وسوف نشرح في هذا المقال بعض انواع الشقق والفروق بينها بالاضافة الي مميزات كل نوع من اجل الوصول الي استثمار مربح وجيد يحقق اعلي معدل ربحية بالاضافة الي ضمان الوصول للقرار السليم في السكن المميز.


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">شقق البنتهاوس :

</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هي عبارة عن وحدات فاخرة جدا تكون في الاداور العلوية من المباني المرتفعة وأحيانا اخري نجدها في الادوار السفلية ومصاحب لها البيزمنت او بما يسمي بالبدروم اصطلاحا .


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              تتميز هذه المجموعة الرائعة من الوحدات بخصوصيتها الكبيرة فالموقع المرتبط بها يجعلها منعزلة تماما عن ما حولها فهي قريبة الي حد كبير من الفيلل في نمط المعيشي الهادئ الراقي المريح جدا والذي يتمتع بقدر كبير من الخصوصية كذلك في بعض الاحيان نجد ان هذه الوحدات يكون مرفق بها مصعد خاص بها منفصل تماما عن المصاعد الموجودة بالمبني ككل مما يجعل الاستثمار في تلك الشقق السكنية فاخر ومربح جدا .


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">شقق الدوبلكس :

</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هي عبارة عن وحدتين سكنيتين كاملتين يقعان فوق بعضهما البعض ويربط بينهما سلم داخلي ، فلكل طابق يعد شقة منفصلة كاملة الغرف من حمام ومطبخ ومنطقة معيشة وغرف نوم وفي بعض الاحيان يرغب الكثير من العملاء في عمل الدور الارضي هو للمعيشة والمطبخ والطابق العلوي هو لباقي الغرف من نوم و حمامات وملابس .


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              تناسب هذه الوحدات الدوبلكس العائلات الكبيرة وهم الذين لا يستطيعون تحمل تكلفة الفيلا الكاملة فتتميز بالسعر المقبول الغير مكلف بالمقارنة بالفيلل المنفصلة وبالاضافة الي ذلك نجد ان معظم الوحدات الدوبلكس يكون مرفق معها حديقة خاصة مما يجعلها مثالية للسكن الراقي والرفاهية المرغوبة والمطلوبة بكثرة في المدن الجديدة.


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">شقق التربلكس :

</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هي شبيه الي حد كبير بالوحدات الدوبلكس بالظبط والاختلاف هنا في انها عبارة عن ثلاث وحدات سكنية فوق بعضها البعض يربط بينهما سلم داخلي ، وتعتبر مثالية الي حد كبير في العائلات الكبيرة ، واحيانا نجد ان صاحب العقار يقوم بتأجير طابق واحد او اكثر بحسب حاجتة.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              في بعض الاحيان يستغل صاحب العقار او التربلكس في استغلال طابق كامل لعمل مكتب خاص به او نادي صحي متكامل الخدمات داخل بيتة ، فالمساحة الكبيرة هذه تجعل التربلكس من الوحدات المميزة الرائعة لكل من يرغب في السكن الراقي المميز .


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              شقق عادية مختلفة المساحات :


              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هي الوحدات التي يذهب الكثير لشراءها وهي المتعارف عليها في العموم فهي النوع المعتاد فهي مناسبة للجميع وتتميز بملائمتها لجميع انماط الحياة المختلفة ، فمنها ما يتميز بغرفة نوم واحدة ومنها ما يتميز بالغرفتين والثلاث غرف بحسب المساحة الكلية الخاصة بالوحدة.


              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هذه الوحدات تتميز بانها في متناول الجميع وبلا شك علي حسب المنطقة ايضا فكل اسرة تختار الوحدة المناسبة لامكانيتها المادية وكذلك لعددها. من هذه الوحدات ايضا ما يسمي بالمتكرر ومنها ما يسمي بالشقق الارضي بحديقة خاصة وهذه الوحدات تتميز بان يوجد معها حديقة خاصة تتميز بالخصوصية والرقي ايضا .


              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">الشقق الاستوديو :

</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هذه الوحدات الصغيرة في المساحة هي المفضلة لكثير من الشباب والعمالة الذين يريدون تقليص النفقات السكنية الكبيرة ، فتتميز الوحدات الاستوديو برخص ثمنها والمساحة الصغيرة ايضا فهي تتألف من غرفة واحدة ومطبخ أمريكي وحمام وأحيانا يوجد غرفة معيشة وهذا النوع يتناسب مع عدد كبير من انماط الحياة الشبابية الغير متزوجين وتناسب ايضا فئة العمالة فهي اكثر وحدات تعتبر مناسبة لظروفهم المادية .


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">الشقق الرووف :

</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              هذا النوع الاخير من الوحدات والتي تتواجد في الدور الاخير المرتفع الذي لا توجد وحدات اعلاه ، هذه الوحدة المميزة تعتبر اجمل الوحدات وارقاها علي الاطلاق فهي تتميز بالمساحة الواسعة والاطلالة الرائعه المفتوحة الغير مجروحة فتتميز هذه الوحدة التي يطلق عليها اصطلاحاً اسم روف ، بأنها مثالية للعائلات فيتم استغلال مساحة الروف الكبيرة في عمل إستراحات للاستجمام والراحة والشوي واستقبال الضيوف ايضا وجزء خاص بالمساحات الترفيهيه الخاصة بالاطفال وغيرها ، اما عن الجزء المبني فهو خاص بغرف النوم والحمام والمطبخ ، تتمتع هذه الوحدات بالذوق الراقي الرفيع والتي يسعي الكثير لشراء هذه الوحدات المثالية.


              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
