import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news1 from "../Images/news1.jpg";

export default function News1Ar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
            اخبار A.S. Properties{" "}
          </h1>
          <p dir="rtl" lang="ar">
            حلول متقدمة لمنزلك الجديد
          </p>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: '15px',
              bottom: '-30px',
              background: '#9f8054',
              width: '85%',
              padding: '40px 15px',
              left: 'auto',
              margin: '0'
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              الأخبار{" "}
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center" dir="rtl" lang="ar">
              {" "}
              {/* <b>01</b> */}
              <h1 dir="rtl" lang="ar">نصائح للمالِك لاختيار الوسيط العقاري الأنسب</h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news1} alt="" />
            </div>
            <div className="col-md-12">
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                إذا كنت مالِكاً لعقار واحد أو أكثر وليس لديك تفرُغ لإدارة
                العقارات، أو في حالة عدم توافر الخبرة الكافية لديك، فمن الأفضل
                إسناد المهمة لـ الوسيط العقاري متخصص في تقديم الخدمات العقارية،
                نظراً لما تحتاجه العملية من وقت وجهد مُضاعف، لذلك عليك البحث عن
                وسيط ذي خبرة عالية، والأهم من ذلك أن يتبع نظاماً محوسباً في
                العمل العقاري.
              </p>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                {" "}
                حيث يتوجب على المالك التأني في اختيار الوسيط العقاري لإدارة
                العقارات، ولكن هناك عدد من المهام الواجب على الوسيط العمل بها،
                قبل عملية اختياره من المالِك وذلك لدراسة مدى قدرة عمله بإمكانات
                مثالية وذات جودة عالية، وهي:
              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                كيفية إدارة العقارات من قِبَل الوسيط العقاري
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                يَبْنِي الوسيط العقاري أسلوب عمله وفقاً لخبرته ليساهم في تطوير
                خدماته المُقدمة لعملائه، ويلجأ عدد من الوسطاء العقاريين الذين
                يمارسون عملهم في مجال إدارة العقارات إلى التعاون المثمر مع منصة
                أساس العقارية، وذلك لما توفره للمنشآت من حزمة متكاملة من البرامج
                والأنظمة العقارية بتعدد استخداماتها وفوائدها المُقدَّمة لموظفي
                المنشأة.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                قدرة الوسيط العقاري على تحديد معدلات الإيجار المناسبة
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                قد تحتاج ك مالِك عملية إرشاد في عملية تحديد معدل إيجار الوحدات
                العقارية، حيث يختلف سعر إيجار كل وحدة عقارية عن الأخرى وفقاً
                لعدة عوامل مختلفة، وهنا تأتي مهمة الوسيط العقاري في تحديد سعر
                الإيجار المناسب.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                متابعة أعمال إدارة عقارات المالك
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                تُعد عملية إدارة عقود الإيجار وإدارة مهام التحصيل وتدوين كافة
                البيانات والحركات المالية من أهم أعمال الوسيط العقاري. إلى جانب
                توفير منصة أساس العقارية لبوابة إلكترونية مُخصصة للمُلّاك وهي
                “بوابة المُلّاك” والتي تعمل كوسيط بين كلٍ من المُلّاك ومنشآت
                الوساطة العقارية، ويتمكن من خلالها المالك المتابعة عن قُرب لكافة
                المعلومات الخاصة بـ عقاراته، ومعرفة الوحدات المؤجرة أو الغير
                المؤجرة متجر عطور ، ويتعرف على عدد العقارات المُدارة وصولاً إلى
                معرفته الدورية لرصيده الحالي.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                تحصيل الإيجارات الشهرية في الوقت المحدد
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                جميعنا يعلم أن قدرة التزام المستأجر لدفع الإيجار قد تكون صعبة
                أحياناً، حيث يقوم الوسيط العقاري بوضع مجموعة من الضوابط لضمان
                تحصيل الإيجار المُتفق عليه مع المستأجر في موعده المحدد. لذلك
                توفر منصة أساس العقارية بوابة إلكترونية خاصة بالمستأجرين وهي
                “بوابة المستأجرين” وتعمل ك وسيط إلكتروني بين كُلٍ من منشأة
                الوساطة العقارية والمستأجر، وذلك لإضافة كافة الحركات المالية
                وتذكيره بالدفعات المستحقة عليه، إلى جانب إمكانية إرسال إشعار
                للمستأجر عبر البريد الإلكتروني لتذكيره بدفع الإيجار قبل يوم
                الدفع المُحدد أو بعده.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                القدرة على تسويق العقارات والإعلان عنها
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                امتلاك الوسيط العقاري الخبرة الطويلة في مجال تسويق وإدارة
                العقارات، فهو يعرف الوقت والمكان المناسبين للإعلان عن الوحدات
                العقارية، وكيفية صياغة محتوى إعلاني مُلفت، وهي ميزة مهمة تقدمها
                منصة أساس العقارية حيث توفِّر منصة السوق العقاري المتخصصة في
                الإعلان عن الوحدات العقارية.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                العثور على المستأجرين المناسبين
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                الوسطاء العقاريون يمتلكون الخبرات الكافية في معرفة المستأجر
                الجيِّد، وذلك نظراً لأنهم يهتمون لكافة التفاصيل المُتعلقة بشخصية
                المُستأجر إلى جانب سلامته القانونية والأمنية، والتحقق من قدرته
                الماليّة على دفع الإيجار.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                إدارة العلاقة بين كل من المستأجر والمالك
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              إدارة العلاقة بكافة جوانبها بين المستأجر والمالك، حيث يُعد الوسيط العقاري حلقة وصل مثالية بينهم لضمان تنظيم عمليات دفع الإيجار وكافة الحركات المالية، وكل ما يتعلق بالصيانة الدورية والطارئة وكيفية استقبال تذكرة الصيانة والتعامل مع الطلب لحله بواسطة مزودي الخدمات، إلى جانب إدارة أي موقف يتطلب إيجاد الحلول الجذرية المناسبة.


              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
              بناء علاقات قوية مع مزودي الخدمات
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
              لن يخلو الأمر من حاجتك إلى عمال الصيانة بواسطة مزودي الخدمات باختلاف أعمالهم، وهذا أمر يصعب عليك كمالك عقار تحقيقه خاصة وأنك ترغب في البحث عن من يؤدي عمله بجودة عالية وأفضل سعر ممكن، وهنا يتولى الوسيط العقاري مهام الإشراف على كل ما يتعلق بالصيانة. توافُر عدد من المهام المُتقنة التي يُقدمها الوسيط العقاري باستخدامه التقنيّات التكنولوجية المُبتكرة، هو سبب مُقنع وكافٍ لكَ ك مالِك للتعاون مع وسيط يعمل بانتظام وجِد. 


              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
