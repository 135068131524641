import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news5 from "../Images/news5.jpg";

export default function News5() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1> A.S. Properties News</h1>
          <p>Advanced Solutions to your new home</p>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                Home
              </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              A.S. Properties News
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center">
              {" "}
              {/* <b>01</b> */}
              <h1>Real estate appraisal terminology in details.</h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news5} alt="" />
            </div>
            <div className="col-md-12">
              <h3 style={{ color: "#9f8054" }}>Real estate appraisal:</h3>
              <p style={{ textAlign: "justify" }}>
                The process of real estate appraisal is the act of estimating
                the value of real properties based on professional principles
                and using specific scientific methodologies. The purpose of
                valuation is to determine the value of properties for various
                purposes, including sale, purchase, insurance, lending, and
                more.
              </p>

              <h3 style={{ color: "#9f8054" }}>Real estate appraiser:</h3>
              <p style={{ textAlign: "justify" }}>
                A real estate appraiser is someone who possesses the necessary
                ability, expertise, and knowledge to conduct the appraisal
                process. They follow methods and standards of analysis to
                determine the fair value of a property.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                Elements of the appraisal process:
              </h3>
              <p style={{ textAlign: "justify" }}>
                The real estate appraisal process involves three essential
                elements to achieve its desired outcome: <br /> 1. Appraiser: This
                refers to the qualified individual who possesses the necessary
                qualifications and expertise to perform the appraisal. <br /> 2.
                Property: The property being appraised, which is the subject of
                the appraisal process. <br /> 3. Appraiser's client: This refers
                to the party or entity that benefits from the appraisal process.
                It is important for the appraiser to adhere to appraisal
                standards while serving the client's interests.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                Principles of professional appraisal practice:
              </h3>
              <p style={{ textAlign: "justify" }}>
                Ethical Principles: <br /> 1. Good character, behavior, and integrity.
                <br /> 2. Neutrality and independent opinion.
                <br /> 3. Objectivity in gathering and analyzing information.
                <br /> 4. Confidentiality in dealing with all appraisals and
                their information.
                <br /> 5. Refusal to accept any appraisal assignment with
                pre-existing information or predetermined results.
                <br /> 6. Avoidance of providing misleading, deceptive, unclear,
                or falsified information.
                <br /> 7. Non-acceptance of any compensation other than the
                agreed-upon appraisal fees.
              </p>

              <h3 style={{ color: "#9f8054" }}>Competence Principle:</h3>
              <p style={{ textAlign: "justify" }}>
                1. Refusal to accept any appraisal assignment unless the
                appraiser possesses the necessary skills and expertise to
                complete it.
                <br /> 2. The appraiser clearly explains in their report the
                methods employed in the appraisal process.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                The difference between Appraisal and Evaluation lies in the
                distinction between Price and Value.
              </h3>
              <p style={{ textAlign: "justify" }}>
                Price: It refers to the consideration or compensation agreed
                upon between the buyer and seller, especially in a sales
                contract, whether it is low or high, based on their mutual
                agreement.
                <br />
                Value: It represents the worth or monetary estimation of an
                item, not only in a sale but also in other contexts. It
                corresponds to the financial worth of the object and its
                equivalent in reality as determined by appraisers.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                The difference between Appraisal and Pricing lies in the
                distinction between Price and Price.
              </h3>
              <p style={{ textAlign: "justify" }}>
                Price: Originally, it refers to what is substituted instead of
                the sale by mutual agreement of the parties involved.
                <br />
                Price: It applies to the commonly determined price of a
                commodity. Whether it is below or above the value, people agree
                upon it or it is determined by the responsible entity.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                The importance of real estate appraisal:
              </h3>
              <p style={{ textAlign: "justify" }}>
                1- Preserving available financial resources. <br />
                2- Reducing real estate speculation to support market balance.
                <br />
                3- Supporting the financial positions of companies by evaluating
                their real estate assets.
                <br />
                4- Assisting in urban planning.
                <br />
                5- Facilitating the separation of rights between parties
                involved.
                <br />
              </p>

              <h3 style={{ color: "#9f8054" }}>
                Purposes of real estate appraisal:
              </h3>
              <p style={{ textAlign: "justify" }}>
                1- Determining the value for sellers, buyers, or real estate
                funds. <br />
                2- Assessing whether the current use of the property is the
                highest and best use (demolition and redevelopment). <br />
                3- Budgeting purposes. <br />
                4- Mortgage financing. <br />
                5- Compensation for expropriation. <br />
                6- Resolving disputes in legal cases. <br />
                7- Dissolving partnerships between heirs or partners. <br />
                8- Knowing the value of properties for exchange purposes. <br />
                9- Facilitating the sale or purchase of properties through
                marketing methods (public auction). <br />
                10- Insurance purposes for real estate.
              </p>

              <h3 style={{ color: "#9f8054" }}>
                The sectors benefiting from appraisal:
              </h3>
              <p style={{ textAlign: "justify" }}>
                Individuals: Selling, buying, renting, and zakat assessment.{" "}
                <br />
                Companies: Mergers, liquidation, acquisitions, development,
                financing, insurance, and asset valuation. <br />
                Government entities: Compensation and dispute resolution. <br />
                Legal professionals: Judicial guardianship, estate distribution,
                and legal disputes. <br />
                Lending and financing institutions: Asset valuation and loan
                estimation.
              </p>
              <h3 style={{ color: "#9f8054" }}>
                The effects of the appraisal process:
              </h3>
              <p style={{ textAlign: "justify" }}>
                In general, the appraisal process involves the integration of
                connected methods and mechanisms designed to produce a realistic
                and convincing estimation of the market value of a property.
              </p>

              <h3 style={{ color: "#9f8054" }}>The effects on society:</h3>
              <h5 style={{ color: "#9f8054" }}>Positive effects:</h5>
              <p style={{ textAlign: "justify" }}>
                1- Helps establish a database of real estate prices, building
                materials, and building areas. <br />
                2- Regulates the process of property registration or land
                registration. <br />
                3- Facilitates the control of real estate prices. <br />
                4- Identifies growth areas and controls urban expansion,
                ensuring its alignment with development plans.{" "}
              </p>

              <h3 style={{ color: "#9f8054" }}>Negative effects:</h3>
              <p style={{ textAlign: "justify" }}>
                A- With the proliferation of appraisers, there is a risk of
                spreading unethical practices in appraisal that can lead to
                manipulation, bankruptcy, deception, or false promises of
                excessive profits.
                <br />
                B- Determining the value of land based on inaccurate data or
                without proper knowledge and expertise.{" "}
              </p>

              <h3 style={{ color: "#9f8054" }}>Mechanism of the appraisal process:</h3>
              <p style={{ textAlign: "justify" }}>
              1- Client request: The process starts with a client requesting the appraisal of a specific property, preferably through an official letter.
<br />
2- Study and financial proposal: A thorough study is conducted, and a financial proposal is prepared, taking into consideration the cost of engaging a specialized expert if necessary.
<br />

3- Request for necessary documents: The required documents for the property being appraised are requested.
<br />

4- Site and building inspection: A site visit is conducted to inspect the location and existing structures.
<br />

5- Purpose of the appraisal: The intended purpose of the appraisal is determined.
<br />

6- Appraisal timeline: The estimated timeframe for completing the appraisal is provided.
<br />

7- Comprehensive report: A complete appraisal report is prepared for the client, including a certified certificate.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
