import React,{useEffect} from 'react';
import Fade from 'react-reveal/Fade';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import gallery1 from '../../Components/Images/3a9aaaf502.jpeg';
import gallery2 from '../../Components/Images/4ad589a27c.jpeg';
import gallery3 from '../../Components/Images/8dda8617dc.jpeg';
import gallery4 from '../../Components/Images/32a662528d.jpeg';
import gallery5 from '../../Components/Images/3700d0ae24.jpeg';
import gallery6 from '../../Components/Images/4213d2b2b5.jpeg';
import gallery7 from '../../Components/Images/8432c116af.jpeg';
import gallery8 from '../../Components/Images/12116dcf95.jpeg';
import gallery9 from '../../Components/Images/c34db5afb0.jpeg';
import gallery10 from '../../Components/Images/e9df794f13.jpeg';
import gallery11 from '../../Components/Images/e90625794c.jpeg';
import gallery12 from '../../Components/Images/fff8f177eb.jpeg';

const photos = [
  { id: 1, src: gallery1, alt: 'Photo 1' },
  { id: 2, src: gallery2, alt: 'Photo 2' },
  { id: 3, src: gallery3, alt: 'Photo 3' },
  { id: 4, src: gallery4, alt: 'Photo 4' },
  { id: 5, src: gallery5, alt: 'Photo 5' },
  { id: 6, src: gallery6, alt: 'Photo 6' },
  { id: 7, src: gallery7, alt: 'Photo 7' },
  { id: 8, src: gallery8, alt: 'Photo 8' },
  { id: 9, src: gallery9, alt: 'Photo 9' },
  { id: 10, src: gallery10, alt: 'Photo 10' },
  { id: 11, src: gallery11, alt: 'Photo 11' },
  { id: 12, src: gallery12, alt: 'Photo 12' },




  // Add more photos here
];
export default function GalleryAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
<>
<header class="page-header" data-stellar-background-ratio="1.15">
	<div class="container">
		<h1  dir="rtl" lang="ar"> المعرض</h1>
    <Fade bottom duration={1000} delay={500}>	<p  dir="rtl" lang="ar">نحن نتطلع إلى الاستماع منك! إذا كان لديك أي أسئلة أو استفسارات، أو ترغب في ترتيب زيارة لعقاراتنا، فلا تتردد في التواصل معنا.</p></Fade>
		  <ol class="breadcrumbAr"  dir="rtl" lang="ar" style={{    position: 'absolute',
   right: '15px',
   bottom: '-30px',
   background: '#9f8054',
   width: '85%',
   padding: '40px 15px',
   left: 'auto',
   margin: '0'
    }}>
    <li class="breadcrumbAr-item"><Link to='/' style={{textDecoration:'none'}}>الرئيسية</Link></li>
    <li class="breadcrumbAr-item active" aria-current="page">المعرض </li>
  </ol>
	</div>

</header>


      <section class=" section-padding" style={{paddingTop:'60px',paddingBottom:'60px'}}>
            <div class="container">
            <div className="galleryContainer">
      <Slider {...settings}>
        {photos.map(photo => (
          <div key={photo.id}>
            <img src={photo.src} style={{height:'600px', width:'100%',objectFit:'cover'}} alt={photo.alt} />
          </div>
        ))}
      </Slider>
    </div>
            </div> 
        </section>
</>  )
}
