import React,{useEffect,useState} from "react";
import slide1 from "../Images/slide01.jpg";
import slide2 from "../Images/slide02.jpg";
import slide3 from "../Images/slide03.jpg";
import service from "../Images/services-icon07.png";
import service2 from "../Images/services-icon08.png";
import side from "../Images/side-image01.jpg";
import m2 from "../Images/icon-m2.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-multi-carousel/lib/styles.css";
import Employees from "../Images/icon-benefits01.png";
import Developers from "../Images/icon-benefits02.png";
import Projects from "../Images/icon-benefits05.png";

import city from "../Images/Untitled-1-10.png";
import emmar from "../Images/Untitled-1-04.png";
import c from "../Images/Untitled-1-08.png";
import misr from "../Images/Untitled-1-06.png";
import lavista from "../Images/Untitled-1.png";
import lmd from "../Images/Untitled-1-11.png";
import hassan from "../Images/Untitled-1-01.png";
import tatweer from "../Images/Untitled-1-07.png";
import { Link } from "react-router-dom";
import sodic from "../Images/Untitled-1-09.png";
import view from "../Images/Untitled-1-03.png";
import capital from "../Images/Untitled-1-02.png";
import palm from "../Images/Untitled-1-06.png";
import gallery1 from "../Images/gallery-thumb01.jpg";
import gallery2 from "../Images/gallery-thumb02.jpg";
import gallery3 from "../Images/gallery-thumb03.jpg";
import news1 from "../Images/news1.jpg";
import news2 from "../Images/news2.jpg";
import news3 from "../Images/news3.jpg";


import Fade from "react-reveal/Fade";
import ScrollTrigger from "react-scroll-trigger";
import CountUp from "react-countup";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCaretRight, faFacebookF, faTwitter, faGoogle, faYoutube } from "@fortawesome/free-solid-svg-icons";
// import { faFacebookF, faTwitter, faGoogle, faYoutube } from "@fortawesome/free-brands-svg-icons";
export default function HomeAr() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [counterOn, setCounterOn] = useState(false);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      slidesToSlide: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3,
    },
  };

  const slides = [
    {
      background: "../Images/slide01.jpg",
      title: "Udført Luxury Residences",
      subtitle: "Living Spaces in Pecherska - Kiev",
      // icon: "images/services-icon01.png",
    },
    {
      background: "../Images/slide02.jpg",
      title: "Oxøme Premium Flats",
      subtitle: "Provide a decent level of comfort",
      // icon: "images/services-icon08.png",
    },
    {
      background: "../Images/slide03.jpg",
      title: "Hømepark Elite Residences",
      subtitle: "We build your dream house",
      // icon: "images/services-icon07.png",
    },
  ];
  return (
    <>
      <header class="slider ">
        <div
          class="slider-container"
          style={{ opacity: "1", display: "block" }}
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide "
              data-stellar-background-ratio="1.15"
              style={{ height: "100vh" }}
            >
              <div class="container">
                <h1>
                  <span>A.S.</span> Properties
                </h1>
                <h2 >حلول متقدمة لمنزلك الجديد</h2>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                <i class="fas fa-caret-left"></i>  احصل على إستشارة 
                </Link>
                {/* <figure>
                  <img src={service2} alt="Image" />
                </figure> */}
              </div>
            </div>
          </div>

          <div class="inner-elements">
            <div class="container">
              <div class="pagination"></div>
              {/* 
        <div class="button-prev">PREV</div>
     
        <div class="button-next">NEXT</div> */}

              <div class="social-media" style={{zIndex:'11'}}>
                <h6>وسائل التواصل الاجتماعي</h6>
                <ul>
                 
                  <li>
                    <a
                      href="https://www.facebook.com/a.s.propertiesegypt"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/a-s-properties"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/a.s.propertiesegypt"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <section class="intro">
        <div class="container">
          <div class="row align-items-center">
         
            <div class="col-lg-6">
              <figure>
                <div class="pattern-bg" data-stellar-ratio="1.07"></div>
                <div class="holder" data-stellar-ratio="1.10">
                  {" "}
                  <img src={side} alt="Image" />
                </div>
              </figure>
            </div>
            <div class="col-lg-6  ">
              <div class="content-box" dir="rtl" lang="ar">
                <b>01</b>
                <Fade bottom duration={1000} delay={500}>
                  <h4 dir="rtl" lang="ar">
                    <span>من نحن؟</span>
                  </h4>{" "}
                </Fade>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <h3 dir="rtl" lang="ar">مساحات المعيشة للأشخاص الإبداعيين</h3>
                </Fade>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <p dir="rtl" lang="ar" style={{paddingRight:'0'}}>
                  A.S Properties هي وكالة استشارات عقارية مقرها في مصر أسسها ويديرها مستشارون عقاريون مؤهلون تأهيلا عاليا.
                  </p>
                </Fade>
                <Fade bottom duration={1000} delay={500}>  <Link dir="rtl" lang="ar"
              to="/about"
                class="link"
                style={{ textDecoration: "none" }}
              ><img src={m2} alt="Image" style={{marginLeft:'5px'}} />
               اقرأ أكثر عنا <i class="fas fa-caret-left"></i>
              </Link>{" "}</Fade>
                {/* <Link to="/about" style={{ textDecoration: "none" }}>
                  
                  Read More About Us
                </Link> */}
              </div>
            </div>
            
          </div>
        </div>
      </section>
      <section class="logos">
        <div class="container">
          <section
            class="benefits"
            style={{ padding: "0", background: "transparent" }}
          >
            <div class="container">
              <div class="row">
                <div class="col-12 wow fadeInUp">
                  {" "}
                  <b>02</b>
                  <Fade bottom duration={1000} delay={500}>     <h4>
                    <span>A.S.</span> Properties
                  </h4></Fade>
                  <Fade bottom duration={1000} delay={500}>    <h3>مطورينا</h3></Fade>
                </div>{" "}
              </div>{" "}
            </div>
          </section>
          <div class="row">
            <Carousel
            
              swipeable={false}
              draggable={false}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={6000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              // deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
            >
              <div style={{padding:'25px 0px'}}
                
                data-wow-delay="0s"
              >
                <figure>
                  {" "}
                  <img
                    src={city}
                    alt="Image"
                 
                  />
                  <h6>سيتي ايدج</h6>
                </figure>
              </div>

              <div  style={{padding:'25px 0px'}}
                
                data-wow-delay="0.05s"
              >
                <figure>
                  {" "}
                  <img
                    src={emmar}
                    alt="Image"
                 
                  />
                  <h6>إعمار</h6>
                </figure>
              </div>

              <div  style={{padding:'25px 0px'}}
                
                data-wow-delay="0.10s"
              >
                <figure>
                  {" "}
                  <img src={c} alt="Image" />
                  <h6>الكازار</h6>
                </figure>
              </div>

              <div
                
                data-wow-delay="0.15s"
              >
                <figure>
                  {" "}
                  <img
                    src={misr}
                    alt="Image"
                 
                  />
                  <h6>مصر إيطاليا</h6>
                </figure>
              </div>

              <div
                
                data-wow-delay="0.20s"
              >
                <figure>
                  {" "}
                  <img
                    src={lavista}
                    alt="Image"
                 
                  />
                  <h6>لافيستا</h6>
                </figure>
              </div>

              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img src={lmd} alt="Image" />
                  <h6>LMD</h6>
                </figure>
              </div>
              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img src={hassan} alt="Image" />
                  <h6>حسن علام</h6>
                </figure>
              </div>
              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img
                    src={tatweer}
                    alt="Image"
                 
                  />
                  <h6>تطوير مصر</h6>
                </figure>
              </div>
              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img
                    src={sodic}
                    alt="Image"
                 
                  />
                  <h6>سوديك</h6>
                </figure>
              </div>
              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img
                    src={view}
                    alt="Image"
                 
                  />
                  <h6>ماونتن فيو</h6>
                </figure>
              </div>
              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img src={capital} alt="Image" />
                  <h6>كابيتال جروب</h6>
                </figure>
              </div>
              <div
                
                data-wow-delay="0.25s"
              >
                <figure>
                  {" "}
                  <img src={palm} alt="Image" />
                  <h6>بالم هيلز</h6>
                </figure>
              </div>
            </Carousel>
       
          </div>
        </div>
      </section>
      <section class="benefits">
     
        <div class="container"> <ScrollTrigger
                      onEnter={() => setCounterOn(true)}
                      onExit={() => setCounterOn(false)}
                    >
          <div class="row">
          <Fade bottom duration={1000} delay={700}>  <div class="col-12 wow fadeInUp">
              {" "}
              <b>03</b>
              <h4>
                <span>A.S.</span> Properties
              </h4>
              <h3>شركاؤنا في النجاح</h3>
            </div>
            </Fade>
            <div class="col wow fadeInUp" data-wow-delay="0s">
              <figure>
                {" "}
                <img src={Employees} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>موظف</h6>
              <span class="odometer" data-count="28" data-status="yes">
                  {counterOn && (
                              <CountUp
                                start={0}
                                end={20}
                                duration={6}
                                delay={0}
                                prefix=""
                                suffix="+"
                              />
                            )}
              </span>{" "}
              {/* <span class="extra">+</span>{" "} */}
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0.05s">
              <figure>
                {" "}
                <img src={Developers} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>مطور</h6>
              <span class="odometer" data-count="32" data-status="yes">
              {counterOn && (
                              <CountUp
                                start={0}
                                end={200}
                                duration={9}
                                delay={0}
                                prefix=""
                                suffix="+"
                              />
                            )}
              </span>{" "}
              {/* <span class="extra">+</span>{" "} */}
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Projects} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>مشروع</h6>
              <span class="odometer" data-count="79" data-status="yes">
              {counterOn && (
                              <CountUp
                                start={0}
                                end={250}
                                duration={4}
                                delay={0}
                                prefix=""
                                suffix="+"
                              />
                            )}
              </span>{" "}
              {/* <span class="extra">+</span>{" "} */}
            </div>
          </div>
          </ScrollTrigger> </div>
      </section>
      <section class="recent-gallery">
        <div class="container">
          <div class="row align-items-center">
          <div class="col-lg-5 wow fadeInUp" dir="rtl" lang="ar">
              {" "}
              <b>04</b>
              <Fade bottom duration={1000} delay={700}>  <h4 dir="rtl" lang="ar">
                <span>Properties </span >معرض داخلي
              </h4></Fade>
              <Fade bottom duration={1000} delay={700}>    <h3 dir="rtl" lang="ar" style={{paddingRight:'0'}}>مساحات لوكس ليفينج في مصر</h3></Fade>
              <Fade bottom duration={1000} delay={700}>  <Link
                to="/gallery"
                class="link"
                style={{ textDecoration: "none" }}
              >
               شاهد كل الصور<i class="fas fa-caret-left"></i>
              </Link>{" "}</Fade>
            </div>
          <div class="col-lg-7">
              <div class="row inner">
                <div class="col-md-4 wow fadeInUp" data-wow-delay="0s">
                  <figure data-stellar-ratio="1.07">
                    {" "}
                    <a href="images/gallery-thumb01.jpg" data-fancybox>
                      <img src={gallery1} alt="Image" />
                    </a>{" "}
                  </figure>
                </div>

                <div class="col-md-4 wow fadeInUp" data-wow-delay="0.05s">
                  <figure data-stellar-ratio="1.15">
                    {" "}
                    <a href="images/gallery-thumb02.jpg" data-fancybox>
                      <img src={gallery2} alt="Image" />
                    </a>{" "}
                  </figure>
                </div>

                <div class="col-md-4 wow fadeInUp" data-wow-delay="0.10s">
                  <figure data-stellar-ratio="1.04">
                    {" "}
                    <a href="images/gallery-thumb03.jpg" data-fancybox>
                      <img src={gallery3} alt="Image" />
                    </a>{" "}
                  </figure>
                </div>
              </div>
            </div>
           

           
          </div>
        </div>
      </section>
      <section class="recent-posts" style={{padding:'20px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp" dir="rtl" lang="ar">
              {" "}
              <b>05</b>
              <Fade bottom duration={1000} delay={500}>  <h4 dir="rtl" lang="ar">
              اخبار      <span>A.S.</span> Properties 
              </h4></Fade> 
              <Fade bottom duration={1000} delay={500}>     <small dir="rtl" lang="ar"> <Link
                to="/news"
                class="link"
                style={{ textDecoration: "none" }}
              >
                اطلع على جميع الأخبار <i class="fas fa-caret-left"></i>
              </Link> </small>{" "}</Fade>
             
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s" dir="rtl" lang="ar">
              <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news3'>   <img src={news3} alt="Image" />{" "}</Link>
                </figure>
                <span>15, يناير 2021</span>
                <h6>
                <Link to='/news3' style={{ textDecoration: "none" }}>
                ثلاثة أنواع من الاستثمار العقاري المربح بالتفاصيل
                </Link>
                </h6>
                <Link to='/news3'> <button className="readMore">اقرأ المزيد &nbsp;<i class="fas fa-caret-left"></i></button></Link>

              </div>
            </div>
           

            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.10s" dir="rtl" lang="ar">
              <div class="post-box">
                <figure>
                  {" "}
                  <Link to='/news2'>    <img src={news2} alt="Image" />{" "}</Link>
                </figure>
                <span>17, نوفمبر 2022</span>
                <h6>
                <Link to='/news2' style={{ textDecoration: "none" }}>
                مستقبل السوق العقاري في مصر لعام 2023
                  </Link>
                </h6>
                <Link to='/news2'> <button className="readMore">اقرأ المزيد &nbsp;<i class="fas fa-caret-left"></i></button></Link>

              </div>
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0s" dir="rtl" lang="ar">
              <div class="post-box">
                <figure>
                  {" "}
                 <Link to='/news1'><img src={news1} alt="Image" />{" "}</Link> 
                </figure>
                <span>2, اغسطس 2022</span>
                <h6>
                <Link to='/news1' style={{ textDecoration: "none" }}>
                نصائح للمالِك لاختيار الوسيط العقاري الأنسب 
                  </Link>
                </h6>
                <Link to='/news1'>  <button className="readMore">اقرأ المزيد &nbsp;<i class="fas fa-caret-left"></i></button></Link>
              </div>
            </div>
      
          </div>
        </div>
      </section>
    </>
  );
}
