import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import news5 from "../Images/news5.jpg";

export default function News5Ar() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1 dir="rtl" lang="ar">
            {" "}
            اخبار A.S. Properties{" "}
          </h1>
          <p dir="rtl" lang="ar">
            حلول متقدمة لمنزلك الجديد
          </p>
          <ol
            class="breadcrumbAr"
            dir="rtl"
            lang="ar"
            style={{
              position: "absolute",
              right: '15px',
              bottom: '-30px',
              background: '#9f8054',
              width: '85%',
              padding: '40px 15px',
              left: 'auto',
              margin: '0'
            }}
          >
            <li class="breadcrumbAr-item">
              <Link to="/" style={{ textDecoration: "none" }}>
                الرئيسية
              </Link>
            </li>
            <li class="breadcrumbAr-item active" aria-current="page">
              الأخبار{" "}
            </li>
          </ol>
        </div>
      </header>

      <section class="recent-posts" style={{padding:'40px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp text-center" dir="rtl" lang="ar">
              {" "}
              {/* <b>01</b> */}
              <h1 dir="rtl" lang="ar">
                مصطلحات التثمين العقاري
              </h1>
              {/* <small>Smaller male cones </small>{" "} */}
            </div>
            <div
              className="col-md-12 p-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={news5} alt="" />
            </div>
            <div className="col-md-12">
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">مفهوم التثمين العقاري : </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                عملية التثمين العقاري هي عملية تقدير قيمة الأملاك العقارية على
                أسس مهنية وباستخدام آليات علمية محددة، لاستخدام القيمة في أغراض
                مختلفة منها: البيع أو الشراء أو التأمين أو الاقتراض، أو غير ذلك
                . . .
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">المثمن العقاري : </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                هو من تتوفر لديه القدرة والخبرة والمعرفة اللأزمة لإتمام عملية
                التثمين وذلك بأتباع طرق ومعايير تحليل عملية للوصول للقيمة
                العادلة للعقار.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">عناصر عمليه التثمين :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                يتطلب التثمين العقاري ليحقق أثره على الوجه المطلوب ثلاثة عناصر
                أساسية هي :
                <br /> 1. مثمن : يتصف بالشروط المعتبره للمثمن.
                <br /> 2. العقار: الذي يقع عليه التثمين. <br /> 3. مثمن له: وهو
                الذي يستفيد من عمليه التثمين. مع مراعاة الأخذ بقواعد التثمين.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">قواعد ممارسه مهنه التثمين :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                قاعدة الأخلاق :
                <br /> 1. حسن السيرة ةالسلوك والأمانة.
                <br /> 2. الحيادية والرأي المستقل.
                <br /> 3. الموضوعية في جمع المعلومات وتحليلها.
                <br /> 4. السرية في التعامل مع كافة التثمينات ومعلوماتها.
                <br /> 5. عدم قبول اي مهمة تثمين جاهزة المعلومات أو متضمنة
                لنتائج مسبقة.
                <br /> 6. أن لايقوم يتقديم معلومات مضللة أوبها غش أوغير واضحة أو
                مزورة.
                <br /> 7. عدم الحصول على أى مكافئات غير أتعاب التثمين المعلنة في
                الإتفاق .
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">قاعدة الكفاءة :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                1. عدم قبول اي عملية تثمين مالم يتوفر لدي المثمن القدرة والخبرة
                لإتمامها.
                <br /> 2. يوضح المثمن في تقريره الأساليب التي تم اتبعها في عملية
                التثمين.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                الفرق بين التثمين والتقييم هو الفرق بين الثمن والقيمة :
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                الثمن : هو العوض في عقد البيع خاصة ويكون تحديده عائدا الى اتفاق
                بين البائع والمشتري سواء كان قليلا او كثيرا.
                <br />
                القيمه : فتكون عوضا في البيع وغيره وهي ما يوافق مقدار مالية
                الشيء ويعادله في الواقع بحسب تقويم المقيمين.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                الفرق بين التثمين والتسعير هو الفرق بين الثمن والسعر :
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                الثمن : في الأصل هو ما يجعل بدلاً عن المبيع باتفاق المتبايعين.
                <br />
                السعر : ينطبق على الثمن المحدد الشائع في سلعة من السلع. سواء كان
                دون القيمة أم فوقها أتفق الناس عليه أم حدد من قبل الجهة
                المسئولة.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">أهمية التثمين العقاري :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                1- الحفاظ على الموارد المالية المتاحة. <br />
                2- تقليص المضاربات العقارية لدعم التوازن للسوق العقاري.
                <br />
                3- دعم المراكز المالية للشركات بتقييم أصولها العقارية.
                <br />
                4- يساعد في تخطيط المدن.
                <br />
                5- إفراز الحقوق عن بعضها البعض.
                <br />
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">أغراض التثمين العقاري :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                1- تحديد القيمة للبائع أو المشتري أو للصناديق العقارية. <br />
                2- تحديد ما إذا كان الاستخدام الحالي للعقار هو أعلى وأفضل
                استخدام (الهدم واعاده البناء). <br />
                3- إعداد الميزانيات . <br />
                4- الرهن العقاري. <br />
                5- التعويضات للنزع. <br />
                6- فض النزاعات في القضايا. <br />
                7- تصفية الشراكة بين الورثة أو الشركاء. <br />
                8- معرفة قيمة العقارات في حالة التبادل. <br />
                9- بغرض بيع العقارات أو شرائها بأحد وسائل التسويق (المزاد
                العلني). <br />
                10- في حاله التأمين على العقارات.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">
                القطاعات المستفيده من التثمين :
              </h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar"> 
                الأفراد : البيع / الشراء / التأجير / الزكاة.
                <br />
                الأفراد : البيع / الشراء / التأجير / الزكاة.
                <br />
                الأفراد : البيع / الشراء / التأجير / الزكاة.
                <br />
                القانونيين : الحراسات القضائية / توزيع التركات / النزاعات
                القضائية.
                <br />
                جهات الاقراض والتمويل : تقدير قيمة الاصول / تقدير قيمة القرض.
              </p>
              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">آثار عملية التثمين :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                إن عملية التثمين بشكلعام هي عبارة عن تجميع لطرق وآليات عمل متصلة
                مترابطة متكاملة صممت لإخراج تخمين واقعي ومقنع للقيمة السوقية
                للعقار.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">الأثار على المجتمع :</h3>
              <h5 style={{ color: "#9f8054" }} dir="rtl" lang="ar">آثار إيجابية :</h5>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                1- يساعد على تأسيس قاعدة بيانات لأسعار العقارات ومواد البناء
                ومساحات البناء.
                <br />
                2- ينظم عملية السجل العقاري أو التسجيل العيني للعقار.
                <br />
                3- يساعد على سهولة عملية التحكم في أسعار العقارات.
                <br />
                4- معرفه مناطق النمو والتحكم في التوسع العمراني والتأكد من
                توجهاته.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">آثار سلبية :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                أ- مع كثره المثمنين قد تنتشر الممارسات السيئه للتثمين والتي تفضي
                الى حدوث تلاعبات تؤدي إلى إفلاس البعض أو خداعهم أو إيهامهم
                بارباح فاحشة.
                <br />
                ب- تحديد قيمة الأرض بمعطيات لا ترتبط بعلم ومعرفة وخبرة.
              </p>

              <h3 style={{ color: "#9f8054" }} dir="rtl" lang="ar">آلية عملية التثمين :</h3>
              <p style={{ textAlign: "justify" }} dir="rtl" lang="ar">
                1- طلب العميل : وذلك بتثمين عقار معين والافضل ان يكون الطلب
                بخطاب رسمي .
                <br />
                2- الدراسه وتقديم العرض المالي (يراعى فيه تكلفة الإستعانة بخبير
                متخصص إذا لزم الأمر).
                <br />
                3- طلب المستندات اللازمة للعقار المراد تثمينه .
                <br />
                4- معاينة الموقع والمباني الموجودة.
                <br />
                5- الغرض من التثمين.
                <br />
                6- مدة تنفيذ التثمين.
                <br />
                7- تقديم تقرير كامل للعميل عن التثمين يشمل شهادة معتمدة.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
