import React,{useEffect} from "react";
import Integrity from "../Images/collaborate.png";
import Honesty from "../Images/honesty.png";
import Empathy from "../Images/empathy.png";
import Compassion from "../Images/kindness.png";
import Morality from "../Images/morality.png";
import Guidance from "../Images/guidance.png";
import Respect from "../Images/trust.png";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <header class="page-header" data-stellar-background-ratio="1.15">
        <div class="container">
          <h1>About A.S. Properties</h1>
          <Fade bottom duration={1000} delay={500}>   <p>Advanced Solutions to your new home</p></Fade>
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to='/' style={{textDecoration:'none'}}>Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              About A.S. Properties
            </li>
          </ol>
        </div>
      </header>

      <section class="about-content">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>
                <span>A.S. Properties</span> Construction
              </h2>
              <Fade bottom duration={1000} delay={500}>  <h5 >
                A.S. Properties is a Property Consultancy agency based in Egypt
                founded and run by highly qualified Property Consultants.
              </h5></Fade>
            </div>

            <div class="col-lg-6">
            <Fade left duration={1000} delay={500}>  <p style={{ textAlign: "justify" }}>
              We've been in the Real Estate Market for more than 10 years; we
                started off as investors in the real estate market which
                guarantees your full satisfaction with the quality of services
                we provide.
              </p></Fade>
            </div>

            <div class="col-lg-6">
            <Fade right duration={1000} delay={500}>   <p style={{ textAlign: "justify" }}>
                We always work on providing full and complete
                updated market research that will help you take a
                well-thought-out and accurate decision when buying a new home or
                selling or renting one you already have.{" "}
              </p></Fade>
            </div>

            <div class="col-lg-6 col-sm-12" style={{paddingTop:'2%'}}>
              <h4>Our Vision</h4>
              <Fade left duration={1000} delay={500}> <p style={{ textAlign: "justify" }}>
              At A.S. Properties we are not looking for a one-time client, we are seeking a long-lasting relationship between the company and a satisfied customer.
              </p></Fade>
            </div>
            <div class="col-lg-6 col-sm-12"  style={{paddingTop:'2%'}}>
              <h4>Our Mission</h4>
              <Fade right duration={1000} delay={500}>  <p style={{ textAlign: "justify" }}>
              We’re here to help you find your new home and/or investment, putting into consideration that you get the best R.O.I and R.O.E and the most value out of the investment you are going to put into it.
              </p></Fade> 
            </div>
         
            <section class="benefits" style={{backgroundColor:'transparent', padding:'60px 0'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 wow fadeInUp">
              {" "}
              <b>02</b>
              <Fade bottom duration={1000} delay={500}>     <h4>
                <span>A.S Properties</span> Values
              </h4></Fade> 
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0s">
              <figure>
                {" "}
                <img src={Integrity} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Integrity</h6>
             
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0.05s">
              <figure>
                {" "}
                <img src={Honesty} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Honesty</h6>
             
            </div>

            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Empathy} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Empathy</h6>
             
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Compassion} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Compassion</h6>
             
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Morality} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Morality</h6>
           
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Guidance} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Guidance</h6>
            
            </div>
            <div class="col wow fadeInUp" data-wow-delay="0.20s">
              <figure>
                {" "}
                <img src={Respect} alt="Image" /> <b></b>{" "}
              </figure>
              <h6>Respect</h6>
     
            </div>
          </div>
        </div>
      </section>
      <section class="benefits" style={{backgroundColor:'transparent', padding:'0px 0'}}>
      <div className="container">
      <div className="row">
      <div class="col-12 wow fadeInUp">
              {" "}
              <b>03</b>
              <Fade bottom duration={1000} delay={500}>   <h4>
                <span>Leadership</span> Our Team
              </h4></Fade>
            </div>
      </div></div></section>
            <div class="col">
              <blockquote style={{minHeight:'39vh'}}>
                <h4>
                Anna-Simon Adel
                </h4>
                <strong>CEO</strong> <br />
                <strong>Bachelor of Engineering - Architecture department</strong>
<p style={{ textAlign: "justify" }}>Anna is a passionate Property consultant and investor. Her Study of Architecture adds great value to her work as a property consultant, being able to understand architectural drawings and having a way to help clients have a better vision of the place they are about to invest in.  Starting off as a real-estate investor helps her relate to the client’s needs and thus provides accurate choices to fulfill those needs adding great R.O.I and R.O.E.</p>
              </blockquote>

            
            </div>
            <div class="col">
              <blockquote style={{minHeight:'39vh'}}>
                <h4>
                Samer Anwar
                </h4>
                <strong>Sales Director</strong><br />
                <strong>Bachelor of Law</strong>
<p style={{ textAlign: "justify" }}>Samer is a very qualified property consultant and Sales director. He guides the representatives of A.S Properties on how to be of great use to their clients and is always up to date with the newest and most recent market research. His great knowledge of the market is at the fingertips of our clients and his study of law is nevertheless useful to them when it comes to contracts and legalities.</p>
              </blockquote>

            
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
