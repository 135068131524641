import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/Logo with Name.png";
import footer1 from "../Images/footer-icon01.png";
import footer2 from "../Images/footer-icon02.png";
import footer3 from "../Images/footer-icon03.png";
import Fade from "react-reveal/Fade";
import { isMobile } from "react-device-detect";

export default function Footer() {
  const today = new Date();

  return (
    <>
      <section class="footer-bar">
        <div class="container">
          <div class="inner wow fadeIn">
            <div class="row">
              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s">
                <figure>
                  <img src={footer1} alt="Image" />
                </figure>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <h3>Address Infos</h3>
                </Fade>
                {isMobile ? (
                  <Fade bottom duration={1000} delay={500}>
                    {" "}
                    <p>
                      villa 71, fifth settlement, third district, first
                      neighborhood -{" "}
                      <span style={{ paddingLeft: "23%" }}>Cairo, Egypt</span>{" "}
                    </p>
                  </Fade>
                ) : (
                  <Fade bottom duration={1000} delay={500}>
                    {" "}
                    <p>
                      villa 71, fifth settlement, third district, first
                      neighborhood - Cairo, Egypt
                    </p>
                  </Fade>
                )}
                {/* <Fade bottom duration={1000} delay={500}>  <p>villa 71, fifth settlement, third district,
          first neighborhood - Cairo, Egypt</p></Fade> */}
              </div>

              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.10s">
                <figure>
                  <img src={footer2} alt="Image" />
                </figure>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <h3>Working Hours</h3>
                </Fade>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <p>
                    Every Day{" "}
                    <strong style={{ textDecoration: "none" }}>10:00</strong> to{" "}
                    <strong style={{ textDecoration: "none" }}>18:00</strong>{" "}
                  </p>
                </Fade>
              </div>

              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.15s">
                <figure>
                  <img src={footer3} alt="Image" />
                </figure>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <h3>Contact Us</h3>
                </Fade>
                <Fade bottom duration={1000} delay={500}>
                  {" "}
                  <p>aspropertieseg@gmail.com</p>
                </Fade>
                <br />
                {isMobile ? (
                  <Fade bottom duration={1000} delay={500}>
                    {" "}
                    <p style={{ paddingLeft: "23%" }}>
                      {" "}
                      <a
                        href="tel:0 109 669 9069"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        {" "}
                        +2 0 109 669 9069
                      </a>
                    </p>{" "}
                    <br />
                    <p style={{ paddingLeft: "23%" }}>
                      {" "}
                      <a
                        href="tel:0 100 366 0035"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        {" "}
                        +2 0 100 366 0035
                      </a>
                    </p>
                  </Fade>
                ) : (
                  <Fade bottom duration={1000} delay={500}>
                    {" "}
                    <p style={{ fontSize: "15px" }}>
                      {" "}
                      <a
                        href="tel:0 109 669 9069"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        +2 0 109 669 9069
                      </a>{" "}
                      - 
                      <a
                        href="tel:0 100 366 0035"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        {" "}
                        +2 0 100 366 0035
                      </a>
                    
                    </p>{" "}
                  </Fade>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s">
              {" "}
              <img src={logo} alt="Image" class="logo" />
              <p>
                A.S. Properties is a Property Consultancy agency based in Egypt
                founded and run by highly qualified Property Consultants.
              </p>
            </div>
            <div class="col-lg-2 col-md-6 wow fadeInUp" data-wow-delay="0.10s">
              <ul class="footer-menu">
                <li>
                  <Link to="/" style={{ textDecoration: "none" }}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/about" style={{ textDecoration: "none" }}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/gallery" style={{ textDecoration: "none" }}>
                    Gallery
                  </Link>
                </li>

                {/* <li><Link to='/history' style={{textDecoration:'none'}}>Career</Link></li> */}
              </ul>
            </div>

            <div class="col-lg-2 col-md-6 wow fadeInUp" data-wow-delay="0.15s">
              <ul class="footer-menu">
                <li>
                  <Link to="/news" style={{ textDecoration: "none" }}>
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/contact" style={{ textDecoration: "none" }}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
              <div class="contact-box">
                <h5>CALL CENTER</h5>
                <h3>
                  {" "}
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      opacity: "0.5",
                    }}
                    href="tel:0 109 669 9069"
                  >
                    {" "}
                    +2 0 109 669 9069
                  </a>  <br />
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      opacity: "0.5",
                    }}
                    href="tel:0 100 366 0035"
                  >
                    {" "}
                    +2 0 100 366 0035
                  </a>
                
                </h3>
                <p>
                  <a
                    style={{ textDecoration: "none" }}
                    href="mailto:aspropertieseg@gmail.com"
                  >
                    aspropertieseg@gmail.com
                  </a>
                </p>
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/a.s.propertiesegypt"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/a-s-properties"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/a.s.propertiesegypt"
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12" style={{ textAlign: "center" }}>
              {" "}
              <span class="copyright" style={{ float: "none" }}>
                {" "}
                Copyright &copy; 2023 by{" "}
                <a
                  target="_blank"
                  href="https://neoneg.com/"
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "#fff",
                    fontWeight: "700",
                  }}
                >
                  NEON
                </a>{" "}
              </span>{" "}
              <a
                href=""
                style={{
                  textDecoration: "none",
                  fontWeight: "700",
                  color: "#fff",
                }}
                onClick={() => {
                  window.open(`https://wa.me/+201113588988`);
                }}
              >
                <i
                  className="fa-brands fa-whatsapp fs-5"
                  style={{ color: "#fff", marginRight: "10px" }}
                ></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
