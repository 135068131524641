import React,{useEffect,useState} from 'react';
import loading from '../Images/preloader.gif';
import slide22 from '../Images/slide01 (1).jpg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import Fade from "react-reveal/Fade";

export default function ContactAr() {
  const [name, setName] = useState('');
	const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [phone, setPhone] = useState('');

	const [message, setMessage] = useState('');
  const handleSubmit = (event) => {
		event.preventDefault();
		const data = {
		  name,
		  email,
       phone,
      subject,

		  message
		};

		sendData(data);
	  }
    const sendData = (data) => {
		axios.post('https://a-s-properties.onrender.com/contact/sendMessage', data)
		  .then(response => {
			console.log(response.data);
            swal({
                title: "Good job!",
                text: "تم إرسال بياناتك بنجاح!",
                icon: "success",
                button: {
                  text: "حسنا",
                  value: true,
                },
              }).then((value) => {
                if (value) {
                  window.location.reload();
                }
              });
		  })
		  .catch(error => {
			console.log(error);			swal("عفوًا!", "الرجاء المحاولة مرة أخرى!", "خطأ");

		  });
	  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
<>

{/* <div class="preloader">
  <div class="layer"></div>

  <div class="inner">
    <figure><img src={loading} alt="Image"/></figure>
    <p><span class="text-rotater" data-text="As Properties | Loading">Loading</span></p>
  </div>
  
</div> */}

<header class="page-header" data-stellar-background-ratio="1.15">
	<div class="container">
		<h1  dir="rtl" lang="ar">تواصل معنا</h1>
    <Fade bottom duration={1000} delay={500}>	<p  dir="rtl" lang="ar">نحن نتطلع إلى الاستماع منك! إذا كان لديك أي أسئلة أو استفسارات، أو ترغب في ترتيب زيارة لعقاراتنا، فلا تتردد في التواصل معنا.</p></Fade>
		  <ol class="breadcrumbAr"  dir="rtl" lang="ar" style={{    position: 'absolute',
    right: '15px',
    bottom: '-30px',
    background: '#9f8054',
    width: '85%',
    padding: '40px 15px',
    left: 'auto',
    margin: '0'
    }}>
    <li class="breadcrumbAr-item"><Link to='/' style={{textDecoration:'none'}}>الرئيسية</Link></li>
    <li class="breadcrumbAr-item active" aria-current="page">تواصل معنا</li>
  </ol>
	</div>

</header>
<section class="contact" style={{padding:'40px 0'}}>
  <div class="container">
    {isMobile?
     <div class="row align-items-center">
   <div class="col-lg-6 wow fadeInUp" dir="rtl" lang="ar"> <b>01</b>
   <Fade bottom duration={1000} delay={500}>  <h4><span>A.S.</span>Properties</h4></Fade>
   <Fade bottom duration={1000} delay={500}>  <small  dir="rtl" lang="ar">لا تتردد في التواصل معنا.</small></Fade> </div>
     <div class="col-lg-3 col-md-6 wow fadeInUp"> 
         <address>
         <Fade bottom duration={1000} delay={500}>   <strong  dir="rtl" lang="ar"> مرحبًا</strong></Fade>
         <Fade bottom duration={1000} delay={500}>  <p ><a href="mailto:aspropertieseg@gmail.com" style={{color:'#000',textDecoration:'none'}}>aspropertieseg@gmail.com</a><br/>
          <a style={{color:'#000',textDecoration:'none'}} href="tel:0 109 669 9069">+2 0 109 669 9069</a>
          <br />
         <a style={{color:'#000',textDecoration:'none'}} href="tel: 01003660035">+2 0 100 366 0035</a>
          </p></Fade>
         </address>
      </div>
         <div class="col-lg-3 col-md-6 wow fadeInUp"> 
         <address>
         <Fade bottom duration={1000} delay={500}> <strong  dir="rtl" lang="ar">تفضل بزيارتنا</strong></Fade>  
         <Fade bottom duration={1000} delay={500}>  <p  dir="rtl" lang="ar"> فيلا 71، التجمع الخامس، الحي الثالث - القاهرة - مصر</p></Fade> 
         </address>
      </div>
     
   
       
     </div>
 
    : <div class="row align-items-center">

<div class="col-lg-3 col-md-6 wow fadeInUp"> 
    <address>
    <Fade bottom duration={1000} delay={500}>  <strong  dir="rtl" lang="ar"> مرحبًا</strong></Fade>
    <Fade bottom duration={1000} delay={500}>   <p ><a href="mailto:aspropertieseg@gmail.com" style={{color:'#000',textDecoration:'none'}}>aspropertieseg@gmail.com</a><br/>
     <a style={{color:'#000',textDecoration:'none'}} href="tel:0 109 669 9069">+2 0 109 669 9069</a>
       <br />
         <a style={{color:'#000',textDecoration:'none'}} href="tel: 01003660035">+2 0 100 366 0035</a>
     </p></Fade>
    </address>
 </div>
    <div class="col-lg-3 col-md-6 wow fadeInUp"> 
    <address>
    <Fade bottom duration={1000} delay={500}>  <strong  dir="rtl" lang="ar">تفضل بزيارتنا</strong></Fade>
    <Fade bottom duration={1000} delay={500}>  <p  dir="rtl" lang="ar"> فيلا 71، التجمع الخامس، الحي الثالث - القاهرة - مصر</p></Fade>
    </address>
 </div>

 <div class="col-lg-6 wow fadeInUp" dir="rtl" lang="ar"> <b>01</b>
 <Fade bottom duration={1000} delay={500}>   <h4><span>A.S.</span>Properties</h4></Fade>
 <Fade bottom duration={1000} delay={500}>   <small  dir="rtl" lang="ar">لا تتردد في التواصل معنا.</small> </Fade></div>
  
</div>
}
   
    <div class="row align-items-center">
        <div class="col-lg-6">
        	 <div class="map">
          <div class="pattern-bg" data-stellar-ratio="1.03"></div>
        
          <div class="holder" data-stellar-ratio="1.07"> 
		<iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3455.173380389543!2d31.43832301511411!3d30.00317758189736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDAwJzExLjQiTiAzMcKwMjYnMjUuOCJF!5e0!3m2!1sen!2seg!4v1685533938617!5m2!1sen!2seg"  allowfullscreen></iframe>
         </div>

        </div>
      
        </div>
     
        <div class="col-lg-6">
        	<div class="contact-form">	
			 <form id="contact" name="contact" method="post"  onSubmit={handleSubmit}>
          <div class="form-group">
            <input type="text" name="name" id="name" autocomplete="off" required  value={name} onChange={(e) => setName(e.target.value)}/>
             <span  dir="rtl" lang="ar">الاسم</span>
          </div>
      
          <div class="form-group"> 
            <input type="text" name="email" id="email" autocomplete="off" required value={email} onChange={(e) => setEmail(e.target.value)}/>
            <span  dir="rtl" lang="ar">الايميل</span>
          </div>
      <div class="form-group"> 
            <input type="text" name="phone" id="phone" autocomplete="off" required value={phone} onChange={(e) => setPhone(e.target.value)}/>
            <span  dir="rtl" lang="ar">الموبايل</span>
          </div>
          <div class="form-group"> 
            <input type="text" name="subject" id="subject" autocomplete="off" required value={subject} onChange={(e) => setSubject(e.target.value)}/>
            <span  dir="rtl" lang="ar">الموضوع</span>
          </div>
      
          <div class="form-group"> 
            <textarea name="message" id="message" autocomplete="off" required value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
            <span  dir="rtl" lang="ar">اترك رسالتك</span>
          </div>
     
          <div class="form-group">
            <button  dir="rtl" lang="ar" id="submit" type="submit" name="submit">
				تأكيد
         </button>
          </div>

        </form>
   
         <div class="form-group">
          <div id="success" class="alert alert-success wow fadeInUp" role="alert"> Your message was sent successfully! We will be in touch as soon as we can. </div>
   
        <div id="error" class="alert alert-danger wow fadeInUp" role="alert"> Something went wrong, try refreshing and submitting the form again. </div>

        </div>
    
        </div>
    
        </div>
   
   </div>
  
  </div>

</section>
</>  )
}
