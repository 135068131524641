import React, { useState } from "react";
import logo from "../Images/Logo with Name.png";
import phone from "../Images/icon-phone.png";
import { Link } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function Navbar({ ar, setAr }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMobileMenuToggle = () => {
    console.log("buttonClicked");
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <>
      <nav class="navbar" style={{ padding: "0", position: "absolute" }}>
        {isMobileMenuOpen ? (
          <div className="side-navigation">
            <div className="menu">
              <ul dir="rtl" lang="ar">
                <li>
                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    الرئيسية
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    اقرأ عنا
                  </Link>
                </li>
                <li>
                  <Link
                    to="/gallery"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    المعرض
                  </Link>
                </li>
                {/* <li><Link to='/' style={{textDecoration:'none'}}>Career</Link>
       
        </li> */}
                <li>
                  <Link
                    to="/news"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    الأخبار
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none" }}
                    onClick={handleMobileMenuToggle}
                  >
                    تواصل معنا
                  </Link>
                </li>
              </ul>
              <div class="language" dir="rtl" lang="ar"> <a  onClick={() => {
                        setAr("eng");
                        localStorage.setItem("lang", "eng");
                      }} style={{textDecoration:'none'}}>EN</a></div>
            </div>
          </div>
        ) : null}
        <div class="container">
          <div class="upper-side" style={{ transform: "translateY(0)" }}>
          <div class="language">
              {" "}
              <a
                onClick={() => {
                  setAr("eng");
                  localStorage.setItem("lang", "eng");
                }}
                style={{ textDecoration: "none" }}
              >
                EN
              </a>{" "}
             
            </div>
            <div class="phone-email" style={{    marginLeft: '0',    marginRight: 'auto',
    marginop: '5px',
    textAlign: 'right',color: '#fff'}} >
              {" "}
              <img src={phone} alt="Image" />
           
              <h4><a  style={{textDecoration:'none', color:'#fff', opacity:'0.5'}} href="tel:0 109 669 9069"> +20 109 669 9069</a> <br />
        <a  style={{textDecoration:'none', color:'#fff', opacity:'0.5'}} href="tel: 0 100 366 0035"> +20 100 366 0035</a></h4>
        <small><a style={{textDecoration:'none'}} href="mailto:aspropertieseg@gmail.com">aspropertieseg@gmail.com</a></small> 
              {/* <h4>+2 01003660035</h4>
              <small>
                <a href="#" style={{ textDecoration: "none" }}>
                  aspropertieseg@gmail.com
                </a>
              </small>{" "} */}
            </div>

            
            <div class="logo">
              {" "}
              <Link to="/">
                <img src={logo} alt="Image" />
              </Link>{" "}
            </div>
            {isMobile ? (
              <div class="hamburger" onClick={handleMobileMenuToggle}>
                {" "}
                <span></span> <span></span> <span></span>
                <span></span>{" "}
              </div>
            ) : (
              ""
            )}
          </div>

          <div class="menu" style={{ opacity: "1" }}>
            <ul>
            <li>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  تواصل معنا
                </Link>
              </li>
              <li>
                <Link to="/news" style={{ textDecoration: "none" }}>
                  الأخبار
                </Link>
              </li>
              <li>
                <Link to="/gallery" style={{ textDecoration: "none" }}>
                  المعرض
                </Link>
              </li>
              <li>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  اقرأ عنا
                </Link>
              </li>
             
              <li>
                <Link to="/" style={{ textDecoration: "none" }}>
                  الرئيسية
                </Link>
              </li>
            
            
            
            
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
