import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/Logo with Name.png";
import footer1 from "../Images/footer-icon01.png";
import footer2 from "../Images/footer-icon02.png";
import footer3 from "../Images/footer-icon03.png";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
export default function Footer() {
  const today = new Date();

  return (
    <>
      <section class="footer-bar">
        <div class="container">
          <div class="inner wow fadeIn">
            {isMobile?
            <div class="row">
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.15s">
              <div className="row">
              <div className="col-md-3" style={{  display:'flex' , justifyContent:'end'}}>
                  <figure style={{ margin: "0"}}>
                    <img src={footer3} alt="Image"  style={{float:'right'}}/>
                  </figure>
                </div>
                <div className="col-md-9">
                  {" "}
                  <h3 dir="rtl" lang="ar">
                  تواصل معنا
                  </h3>
                  <p>aspropertieseg@gmail.com</p>
              <br />
              <p style={{fontSize:'15px'}}> 
                <a  style={{
                      textDecoration: "none",
                      color: "#fff",
                  
                    }} href="tel:0 109 669 9069">+2 0 109 669 9069</a>
                 - <a
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                  
                    }}
                    href="tel:0 100 366 0035"
                  >
                    {" "}
                    +2 0 100 366 0035
                  </a></p>
                </div>
               
              </div>
             
            </div>

            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.10s">
            <div className="row">
            <div className="col-md-3" style={{  display:'flex' , justifyContent:'end'}}>
                  <figure style={{ margin: "0" }}>
                    <img src={footer2} alt="Image" style={{float:'right'}} />
                  </figure>
                </div>
                <div className="col-md-9">
                  {" "}
                  <h3 dir="rtl" lang="ar">
                  ساعات العمل
                  </h3>
                  <p dir="rtl" lang="ar">
               يوميا
                <strong style={{ textDecoration: "none" }}>10:00</strong> الي{" "}
                <strong style={{ textDecoration: "none" }}>18:00</strong>{" "}
              </p>
                </div>
               
              </div>
          
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s">
              <div className="row">
              <div className="col-md-3"  style={{  display:'flex' , justifyContent:'end'}}>
                  <figure style={{ margin: "0" }}>
                    <img src={footer1} alt="Image" style={{float:'right'}} />
                  </figure>
                </div>
                <div className="col-md-9">
                  {" "}
                  <h3 dir="rtl" lang="ar">
                    العنوان
                  </h3>
                  <p dir="rtl" lang="ar">
                    فيلا 71، التجمع الخامس، الحي الثالث - القاهرة - مصر
                  </p>
                </div>
               
              </div>
            </div>
          </div>
          :
            <div class="row">
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.15s">
              <div className="row">
                <div className="col-md-9">
                  {" "}
                  <h3 dir="rtl" lang="ar">
                  تواصل معنا
                  </h3>
                  <p>aspropertieseg@gmail.com</p>
              <br />
              <p style={{fontSize:'15px'}}> 
                <a  style={{
                      textDecoration: "none",
                      color: "#fff",
                  
                    }} href="tel:0 109 669 9069">+2 0 109 669 9069</a>
                 - <a
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                  
                    }}
                    href="tel:0 100 366 0035"
                  >
                    {" "}
                    +2 0 100 366 0035
                  </a></p>
                 
                </div>
                <div className="col-md-3">
                  <figure style={{ margin: "0" }}>
                    <img src={footer3} alt="Image" />
                  </figure>
                </div>
              </div>
             
            </div>

            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.10s">
            <div className="row">
                <div className="col-md-9">
                  {" "}
                  <h3 dir="rtl" lang="ar">
                  ساعات العمل
                  </h3>
                  <p dir="rtl" lang="ar">
                كل يوم{" "}
                <strong style={{ textDecoration: "none" }}>10:00</strong> الي{" "}
                <strong style={{ textDecoration: "none" }}>18:00</strong>{" "}
              </p>
                </div>
                <div className="col-md-3">
                  <figure style={{ margin: "0" }}>
                    <img src={footer2} alt="Image" />
                  </figure>
                </div>
              </div>
          
            </div>
            <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s">
              <div className="row">
                <div className="col-md-9">
                  {" "}
                  <h3 dir="rtl" lang="ar">
                    العنوان
                  </h3>
                  <p dir="rtl" lang="ar">
                    فيلا 71، التجمع الخامس، الحي الثالث - القاهرة - مصر
                  </p>
                </div>
                <div className="col-md-3">
                  <figure style={{ margin: "0" }}>
                    <img src={footer1} alt="Image" />
                  </figure>
                </div>
              </div>
            </div>
          </div> }
          
          </div>
        </div>
      </section>
      <footer class="footer">
        <div class="container">
          {isMobile ? (
            <div class="row">
              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s">
                {" "}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <img src={logo} alt="Image" class="logo" />
                </div>
                <p dir="rtl" lang="ar">
                  A.S Properties هي وكالة استشارات عقارية مقرها في مصر أسسها
                  ويديرها مستشارون عقاريون مؤهلون تأهيلا عاليا.
                </p>
              </div>

              <div
                class="col-lg-2 col-md-6 wow fadeInUp"
                data-wow-delay="0.10s"
              >
                <ul
                  class="footer-menu"
                  dir="rtl"
                  lang="ar"
                  style={{ float: "right" }}
                >
                  <li style={{ float: "right" }} dir="rtl" lang="ar">
                    <Link
                      to="/"
                      style={{ textDecoration: "none", float: "right" }}
                      dir="rtl"
                      lang="ar"
                    >
                      الرئيسية
                    </Link>
                  </li>
                  <li style={{ float: "right" }} dir="rtl" lang="ar">
                    <Link
                      to="/about"
                      style={{ textDecoration: "none", float: "right" }}
                      dir="rtl"
                      lang="ar"
                    >
                      اقرأ عنا
                    </Link>
                  </li>
                  <li style={{ float: "right" }} dir="rtl" lang="ar">
                    <Link
                      to="/gallery"
                      style={{ textDecoration: "none", float: "right" }}
                      dir="rtl"
                      lang="ar"
                    >
                      المعرض
                    </Link>
                  </li>

                  {/* <li><Link to='/history' style={{textDecoration:'none'}}>Career</Link></li> */}
                </ul>
              </div>
              <div
                class="col-lg-2 col-md-6 wow fadeInUp"
                data-wow-delay="0.15s"
              >
                <ul
                  class="footer-menu"
                  dir="rtl"
                  lang="ar"
                  style={{ float: "right" }}
                >
                  <li style={{ float: "right" }} dir="rtl" lang="ar">
                    <Link
                      to="/news"
                      style={{ textDecoration: "none", float: "right" }}
                      dir="rtl"
                      lang="ar"
                    >
                      الاخبار
                    </Link>
                  </li>
                  <li style={{ float: "right" }} dir="rtl" lang="ar">
                    <Link
                      to="/contact"
                      style={{ textDecoration: "none", float: "right" }}
                      dir="rtl"
                      lang="ar"
                    >
                      {" "}
                      تواصل معنا
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
                <div
                  class="contact-box"
                  style={{
                    float: "right",
                    textAlign: "right",
                    marginTop: "30px",
                  }}
                >
                  <h5 dir="rtl" lang="ar">
                    تواصل معنا
                  </h5>
                  <h3>
                    {" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        opacity: "0.5",
                      }}
                      href="tel:0 109 669 9069"
                    >
                      {" "}
                      +2 0 109 669 9069
                    </a>
                    <br />
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      opacity: "0.5",
                    }}
                    href="tel:0 100 366 0035"
                  >
                    {" "}
                    +2 0 100 366 0035
                  </a>
                  </h3>
                  <p>
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:aspropertieseg@gmail.com"
                    >
                      aspropertieseg@gmail.com
                    </a>
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/a.s.propertiesegypt"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/a-s-properties"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/a.s.propertiesegypt"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="col-12" style={{ textAlign: "center" }}>
                {" "}
                <span class="copyright" style={{ float: "none" }}>
                  {" "}
                  Copyright &copy; 2023 by{" "}
                  <a
                    target="_blank"
                    href="https://neoneg.com/"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: "700",
                    }}
                  >
                    NEON
                  </a>{" "}
                </span>{" "}
                <a
                  href=""
                  style={{
                    textDecoration: "none",
                    fontWeight: "700",
                    color: "#fff",
                  }}
                  onClick={() => {
                    window.open(`https://wa.me/+201113588988`);
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp fs-5"
                    style={{ color: "#fff", marginRight: "10px" }}
                  ></i>
                </a>
              </div>
            </div>
          ) : (
            <div class="row">
              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.20s">
                <div class="contact-box">
                  <h5 dir="rtl" lang="ar">
                    تواصل معنا
                  </h5>
                  <h3>
                    {" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: "#fff",
                        opacity: "0.5",
                      }}
                      href="tel:0 109 669 9069"
                    >
                      {" "}
                      +2 0 109 669 9069
                    </a>
                    <br />
                  <a
                    style={{
                      textDecoration: "none",
                      color: "#fff",
                      opacity: "0.5",
                    }}
                    href="tel:0 100 366 0035"
                  >
                    {" "}
                    +2 0 100 366 0035
                  </a>
                  </h3>
                  <p>
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto:aspropertieseg@gmail.com"
                    >
                      aspropertieseg@gmail.com
                    </a>
                  </p>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/a.s.propertiesegypt"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <i class="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/a-s-properties"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/a.s.propertiesegypt"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                      >
                        <i class="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-lg-2 col-md-6 wow fadeInUp"
                data-wow-delay="0.15s"
              >
                <ul class="footer-menu" dir="rtl" lang="ar">
                  <li>
                    <Link to="/news" style={{ textDecoration: "none" ,float:'right'}}>
                      الاخبار
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" style={{ textDecoration: "none" ,float:'right'}}>
                      {" "}
                      تواصل معنا
                    </Link>
                  </li>
                </ul>
              </div>
              <div
                class="col-lg-2 col-md-6 wow fadeInUp"
                data-wow-delay="0.10s"
              >
                <ul class="footer-menu" dir="rtl" lang="ar">
                  <li>
                    <Link to="/" style={{ textDecoration: "none" ,float:'right'}}>
                      الرئيسية
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" style={{ textDecoration: "none" ,float:'right'}}>
                      اقرأ عنا
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery" style={{ textDecoration: "none",float:'right' }}>
                      المعرض
                    </Link>
                  </li>

                  {/* <li><Link to='/history' style={{textDecoration:'none'}}>Career</Link></li> */}
                </ul>
              </div>
              <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.05s">
                {" "}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <img src={logo} alt="Image" class="logo" />
                </div>
                <p dir="rtl" lang="ar">
                  A.S Properties هي وكالة استشارات عقارية مقرها في مصر أسسها
                  ويديرها مستشارون عقاريون مؤهلون تأهيلا عاليا.
                </p>
              </div>

              <div class="col-12" style={{ textAlign: "center" }}>
                {" "}
                <span class="copyright" style={{ float: "none" }}>
                  {" "}
                  Copyright &copy; {today.getFullYear()} by{" "}
                  <a
                    target="_blank"
                    href="https://neoneg.com/"
                    style={{
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "#fff",
                      fontWeight: "700",
                    }}
                  >
                    NEON
                  </a>{" "}
                </span>{" "}
                <a
                  href=""
                  style={{
                    textDecoration: "none",
                    fontWeight: "700",
                    color: "#fff",
                  }}
                  onClick={() => {
                    window.open(`https://wa.me/+201113588988`);
                  }}
                >
                  <i
                    className="fa-brands fa-whatsapp fs-5"
                    style={{ color: "#fff", marginRight: "10px" }}
                  ></i>
                </a>
              </div>
            </div>
          )}
        </div>
      </footer>
    </>
  );
}
